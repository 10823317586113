import React from 'react'
import { Parallax } from 'react-parallax';
import { Zoom } from 'react-reveal';
import BrandTwo from './BrandTwo';


const CareerJobList = [
  {

    title: 'Full Stack Developer',
    department: "Designing & Development",
    jobtype: "Full-time",
    location: "Lucknow"



  },
  {

    title: 'Content Writer',
    department: "Content Writing",
    jobtype: "Full-time",
    location: "Lucknow"
  },
  {

    title: 'Ui/Ux Design',
    department: "Designing & Development",
    jobtype: "Full-time",
    location: "Lucknow"

  },
  {

    title: 'Content Writer (INTERNSHIP)',
    department: "Content Writing",
    jobtype: "Full-time",
    location: "Lucknow"

  },

  {

    title: 'SEO Executive (INTERNSHIP)',
    department: "Digital Marketing",
    jobtype: "Full-time",
    location: "Lucknow"

  },
]



const Career = () => {
  return (
    <React.Fragment>

      <div className="rn-about-area ptb--30">
        <div className="rn-columns-area ptb--60 ">
          <Zoom bottom>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="single-column">
                    <div className=" text-center ">

                      <h2>Open Positions</h2>

                    </div>
                    <p className='text-center'>Have a look at the positions we are currently hiring for. The list is frequently updated, so don't forget to check back! Have a look at the in-office and remote positions we are currently hiring for.
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </Zoom>
        </div>
      </div>

      <div className=" ptb--60 bg_color--1">
        <div className="container">




          <div className="row">
            {CareerJobList.map((val, i) => (

              <div className="col-lg-6 col-md-6 col-sm-6 col-12">



                <div className="rn-carrer-post">
                  <span className='icon-5'> </span>

                  <div className="inner">
                    <h4 className="title">{val.title}</h4>
                    <p>{val.department}</p>
                    <p>{val.jobtype}</p>
                    <p>{val.location}</p>




                  </div>
                  <a className="rn-button-style--2 rn-btn-small" href='/contact'>More Details</a>
                </div>

              </div>


            ))}
          </div>



        </div>
      </div>

   
      <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-10.jpg`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
              <BrandTwo/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>


    </React.Fragment>
  )
}

export default Career;
