import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import DiscussProject from '../../../component/common/DiscussProject';
import { Parallax } from 'react-parallax';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const Html5App = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            
            pageTitle="Best Flutter App Development Company in Lucknow "
            metaKeywords="Best Flutter App Development Company in Lucknow "
            metaDescription=" If you are searching for the Flutter App Development Company in Lucknow Ekana Technologies is a best Mobile App Development Company in Lucknow at low price "

            canonical="/Best-Flutter-App-Development-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={4} parent={"Services"}  title={'Flutter App Development'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                 
                                                <p>The expertise of our experienced HTML5 developers offers specialized services through the use of state-of-art mobile technologies.”</p>
                                                <p>HTML5 app Development Company is one of the revolutionary steps in the world of mobile applications, designing and development communities. This technology has played a huge role in facilitating mobile application development at a low cost along with the reduction in the developer’s efforts. The expertise of our experienced mobile app developers offers the best HTML5 Mobile App Solutions to large and small scale businesses. We provide full satisfaction and an experience of a native app at much lower development cost to our clients.  We use HTML5, CSS3, and JavaScript, etc to design and develop multi-platform mobile applications so that application is developed according to the client’s needs.HTML5 is ready to change the mobility face since many technology giants such as Apple, Microsoft making efforts for the promotion of HTML5 technology due to its ability in designing and developing mobile apps and innovative models user interaction.</p>


                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                  

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}



            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>





            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(Html5App);
