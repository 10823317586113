import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";
import { Parallax } from 'react-parallax';
// import BrandTwo from "../../../elements/BrandTwo";
import DiscussProject from '../../../component/common/DiscussProject';


const SocialMedia = () => {
  return (
    <React.Fragment>
      <PageHelmet 
        pageTitle='best Social Media Optimization company in lucknow' 
            
        metaKeywords="best Social Media Optimization company Lucknow, seo company in lucknow, best digital marketing agency in Lucknow, website creators "

        metaDescription=" If you are searching for Social Media Optimization Company in Lucknow Ekana Technologies is a Best Digital Marketing and Software Development Company in Lucknow at low price "
      
        canonical="/best-Social-Media-Optimization-company-in-lucknow"
      
      />

      {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
      {/* Start Breadcrump Area */}
      <Breadcrumb No={8} parent={"Services"} title={' Social Media Optimization'} />
      {/* End Breadcrump Area */}







      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">


                  {/* Start Single Area */}
                  <Zoom bottom>
                    <div className="row sercice-details-content align-items-center">

                      <div className="col-lg-12 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>Social Media is a significant tool to optimize any service by increasing the number of visitors on the website. Social Media Optimized website impacts a larger group of the visitors available across the internet than any other advertising tools that are available nowadays. A significant number of users use social networks where chats and sharing plays an important role. </p>
                          <p>Thus, a company seeking to connect with its users directly can use blogs, forums, communities and popular networks as all of this work spectacularly for this purpose. By being a part of this hot mass obsession you have the potential to reach out with your services among the thousands of users across the web.</p>
                          <p className='mb-3'>Ekana technologies is a well-known SMO company as we are equipped with an expert team of professionals who are thoroughly experienced and skilled in providing custom, innovative, successfully implemented strategies and dedicated social media optimization services which include-</p>
                          <ul className="liststyle">
                            <li>Helps in establishing brand awareness in social networks</li>
                            <li>Driving heavy traffic from social networking sites to your website</li>
                            <li>Fusing internet marketing campaigns and SEO for the site</li>
                            <li>Helping with improving link popularity and gaining non-reciprocal links</li>
                          </ul>
                        </div>
                      </div>

                    </div>
                  </Zoom>
                  {/* End Single Area */}



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}



      {/* Start Brand Area */}
      <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject />
              </div>
            </div>
          </div>
        </div>

      </Parallax>
      {/* End Brand Area */}





      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* <Footer /> */}

    </React.Fragment>
  )
}

export default React.memo(SocialMedia);
