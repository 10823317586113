import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const BannerDesign = () => {
  return (
    <React.Fragment>
      <PageHelmet 
         pageTitle="Best Banner Design Company in Lucknow"
         metaKeywords="Best Banner Design Company in Lucknow "
         metaDescription=" If you are searching for the Banner Design IT Company in Lucknow Ekana Technologies is a best Banner Design Company in Lucknow at low price "
         canonical="/Best-Banner-Design-Company-in-Lucknow"
      
      />

      {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
      {/* Start Breadcrump Area */}
      <Breadcrumb No={12} parent={"Services"} title={' Banner Design'} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--120">
        <div className="container">
          <div className="row">
            <Zoom bottom>
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">

                      <div className="col-lg-12 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>It's a matter of pride for us to have highly skilled and experienced team of designers who are known to create banners that instantly click with the target audience. We have dozens of happy clients across diverse industries. </p>
                          <p>Our unique and professional banners are created keeping in mind the client's needs and are capable of catching worldwide attention for their targeted audience. Our high-quality service, long-lasting relationships, on-time delivery and affordable prices are the reasons why we are the best graphics designing company.</p>
                        </div>
                      </div>

                    </div>
                    {/* End Single Area */}


                  </div>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
   
      {/* End Page Wrapper */}


      {/* Start Brand Area */}
      <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject />
              </div>
            </div>
          </div>
        </div>

      </Parallax>
      {/* End Brand Area */}







      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* <Footer /> */}

    </React.Fragment>
  )
}

export default React.memo(BannerDesign);
