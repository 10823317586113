import React, { Component } from "react";

class ContactFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render() {
        return (
            <div className="contact-form--2">
            <div className="container">
                <div className="row row--35 align-items-start">
                    <div className="col-lg-12 order-2 order-lg-1">
                        <div className="form-wrapper  ">

                            <form>
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="firstname"
                                        id="item01"
                                        value={this.state.rnName}
                                        onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                        placeholder="First Name*"
                                    />
                                </label>
                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="lastname"
                                        id="item01"
                                        value={this.state.rnName}
                                        onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                        placeholder="Lst Name*"
                                    />
                                </label>
                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="phone"
                                        id="item02"
                                        value={this.state.rnSubject}
                                        onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                                        placeholder="Phone Number*"
                                    />
                                </label>

                                <label htmlFor="item04">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item03"
                                        value={this.state.rnEmail}
                                        onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                        placeholder="Your email *"
                                    />
                                </label>

                                <label htmlFor="item05">
                                    <input
                                        type="text"
                                        name="course"
                                        id="item04"
                                        value={this.state.rnSubject}
                                        onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                                        placeholder="Course"
                                    />
                                </label>
                                <label htmlFor="item06">
                                <select 
                                              id="item06"
                                            name="qualification" 
                                      
                                            value={this.state.rnSubject}
                                            onChange={(e) => { this.setState({ rnSubject: e.target.value }); }}
                                            >
                                                <option value="This question is about..">B.Tech</option>
                                                <option value="Web Development">BCA,MCA</option>
                                                <option value="App Development">BSC</option>
                                             
                                                <option value="Other">Other</option>
                                           
                                            </select>
                                 
                                </label>
                                <div className="main-page-form-button">
                                        <button className="rn-button-style--2 btn-solid " type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                             
                                        </div>
                                {/* <button className="rn-button-style--2 btn-solid " type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button> */}
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        )
    }
}
export default React.memo(ContactFour);