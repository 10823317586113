import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const InventorySystem = () => {
    return (
        <React.Fragment>
            <PageHelmet
            pageTitle='The Best Inventory System Development Company in Lucknow'
            metaKeywords="best Inventory System Development company Lucknow,  website creators "
            metaDescription=" If you are searching for the Best Inventory System Development Company in Lucknow Ekana Technologies is a Best Product Development  Company in Lucknow at low price "
            canonical="/The-Best-Inventory-System-Development-Company-in-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={7}  parent={"Services"}  title={'Inventory System Development'} />
            {/* End Breadcrump Area */}







            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>Inventory management is a process that has strategically been followed while getting profoundly involved with manufacturers and vendors. However, almost all companies have already been pursuing this method to get the most comprehensive report of existing as well as required inventory; but with new premeditated Inventory Management System companies find a more detailed description of their stock.</p>

                                                <p>Strategic IMS involves a close discussion with suppliers to identify the useful activities that can strengthen the delivery of the final inventory. In addition to this, many other activities can also be determined and performed in tandem to expedite the inventory supply process and to offer litheness to the procedure of delivering the end product. Many newbie companies think of themselves as successful, but this success is vague if customers do not need the product when it is needed the most.</p>
                                                <p>The insights of the Inventory Management system provide a clear view of the business to a company owner.  With all comprehensive study and reports of the aforementioned facets of inventory management, an enterprise can save a lot of time and funds on stock management and ordering the stock.</p>


                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}





            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(InventorySystem);
