import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Ecommerce = () => {
    return (
        <React.Fragment>
            <PageHelmet 
        
            pageTitle='Best eCommerce Development Company in Lucknow' 
            
            metaKeywords="best web development company in lucknow, wordpress development company in lucknow, best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
          
            metaDescription=" If you are searching for Best eCommerce Development Company in Lucknow Ekana Technologies is a Top Web Development and Software Development Company in Lucknow."
            canonical="/Best-eCommerce-Development-Company-in-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={2}  parent={"Services"}  title={'eCommerce Development'} />
            {/* End Breadcrump Area */}



            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">

                                                <p>The e-commerce industry has totally decentralized the marketplace. The availability of a person is not necessary to buy or sell a product. All the purchases made can be performed through online transactions. This has significantly increased the possibilities of prospective sellers and buyers. A business can now cater to many prospective customers, living in any corner of the world, through this medium and increase his customer base thereby increasing his overall revenue. If you have a business dealing in marketing goods and services, then you ought to use the E-commerce platform. </p>
                                                <p>E-commerce solution provides easy and quick access to the consumers.</p>
                                                <p>We at Ekana Technologies deliver you a high-quality eCommerce solution having robustness and immunity features. E-commerce uses technologies like electronic funds transfer, online transaction processing, internet marketing, electronic data interchange, automated data collection system, and inventory management system along with social media and emails. As online transactions are the key role of an eCommerce web application, it puts it at to higher risk.</p>
                                                <p>Therefore, an eCommerce application needs to be carefully calibrated to sustain and thwart those attacks. This is why you need a group of expert software professionals like us who can develop robust and immune software with high performance and efficiency.</p>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}




            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>




            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(Ecommerce);
