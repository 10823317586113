import React, { Component } from "react";
import ContactForm from "./ContactForm";


class ContactTwo extends Component {
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--30">
                                <h2 className="title">Contact Us.</h2>
                                <p>Have a question? Need help? Don't hesitate, drop us a line.</p>
                         
                            </div>
                            <div className="form-wrapper">
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img width="100%" height="auto" loading="lazy" src="assets/images/about/about-6.webp" alt="Contact Us with Best It Company Ekana Technologies"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default React.memo(ContactTwo);