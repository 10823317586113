import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";

// const Result = () => {
//     return (
//         <div className="col-xl-3 col-xxl-4 col-lg-4 col-md-6">
//             <div className="card">
//                 <div className="card-body">

//                     <div className="card-content">
//                         <div className="sweetalert mt-5">
//                             <button
//                                 onClick={() =>
//                                     swal("Sent successfully!","Your Form has been sent successfully, We will contact you soon!", "success")
//                                 }
//                                 className="btn btn-success btn sweet-success"
//                             >
//                                 Sweet Success
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

function ContactForm({ props }) {
    // const [result, showresult] = useState(false);

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    function sendEmail(e) {
        e.preventDefault();

        console.log("form", e.target)


        emailjs
            .sendForm(
                "service_wd67r8v",
                "template_azf9e1h",
                e.target,
                "Jly865lom_TX8CG6u"
            )
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.log(err));

            swal("Sent successfully!","Your Form has been sent successfully, We will contact you soon!", "success")
         
    }


    // setTimeout(() => {
    //     showresult(true);
    // }, 1000);


    return (
        <form action="POST" onSubmit={sendEmail}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    required
                />
            </div>

            <div className="rn-form-group">


                <select
                    type="text"
                    name="development"
                    form="carform"
                    required
                >
                    <option value="This question is about..">This question is about...</option>
                    <option value="Web Development">Web Development</option>
                    <option value="App Development">App Development</option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="Business Strategies">Business Strategies</option>
                    <option value="Other">Other</option>

                </select>


            </div>

            <div className="rn-form-group">
                <textarea
                    name="message"
                    placeholder="Your Message"
                    required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <ReCAPTCHA
                    sitekey="6LeiS7MmAAAAAC6QbxS4cpVqLESpHZGQLDR07Q_o"

                    onChange={onChange}
                />



                <button
                    className="rn-button-style--2 btn-solid mt-2"
                    type="submit" value="submit" name="submit" id="mc-embedded-subscribe"
                >
                    Submit Now
                </button>
                {/* <button className="rn-button-style--2 btn-solid mt-2" 
                type="submit" value="submit" name="submit" id="mc-embedded-subscribe"
                
                >Submit Now</button> */}
            </div>

            {/* <div className="rn-form-group">

                {result ? <Result /> : null}
            </div> */}

        </form>
    )
}
export default React.memo(ContactForm);
