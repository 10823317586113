import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const ErpDevelopment = () => {
    return (
        <React.Fragment>
            <PageHelmet 
                pageTitle='The Best ERP Development Company in Lucknow'
                metaKeywords="the best erp development company in lucknow, best product development company in Lucknow,  website creators "
                metaDescription=" If you are searching for the Best Product Development Company in Lucknow Ekana Technologies is a Best Product Development  Company in Lucknow at low price "
                canonical="/Best-ERP-Development-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={7}  parent={"Services"}  title={'ERP Development'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-12 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" style={{ maxHeight: "500px" }} src="https://ekanatechnologies.com/wp-content/uploads/2020/01/erp-development-company.jpg" alt="Service Images" />
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>There is a sudden increase in the need for Enterprise Resource Planning software development to manage the organization's day-to-day business activities. ERP is a software-enabled solution used to manage all the information and functions of a business. Different day-to-day business activities managed in the ERP software systems include accounting, procurement, project management, risk management, and compliance, and supply chain operations.</p>

                                                    <p>The ERP software developed by our experts is powerful, customized, secure, reliable and easy to use. Our company provides enterprise business solutions tailored according to the needs of your industry. Since the ERP system is a complex software that requires proper integration in all the business processes of an organization, we follow an individual approach in every development process.</p>

                                                    <h5 className='mt-3 mb-3'>What Our Services Cover</h5>
                                                    <p className='mt-3 mb-3'>ERP is a complex process requiring not only knowledge but also a deeper understanding of the way a business works and what it needs. Our services include-</p>

                                                    <ul className="liststyle">
                                                        <li>Sales, accounting & finance</li>
                                                        <li>Inventory Management, material, dispatch & delivery</li>
                                                        <li>Planning and procurement, vendor tracking</li>
                                                        <li>Human Resource, Attendance, Payroll & Biometrics</li>
                                                        <li>CRM, Marketing etc.</li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}




            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>




            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(ErpDevelopment);
