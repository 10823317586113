import React, { Fragment} from "react";
import { Parallax } from "react-parallax";

import { FiChevronUp } from "react-icons/fi";


// import Header from "../component/header/Header";

import CounterOne from "../elements/counters/CounterOne";

import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import ServiceList from "../elements/service/ServiceList";
import Helmet from "../component/common/Helmet";
import Zoom from 'react-reveal/Zoom';
import Testimonial from "../component/projects/Testimonial";
import Flywheel from "./flywheel/Flywheel";
import ContactOne from "../elements/contact/ContactOne";
import ScrollToTop from "react-scroll-up";
import { Link } from "react-router-dom";







const SlideList = [
  {
    textPosition: 'text-left',
    category: '',
    title: " We Are Here to Make Your Idea's",
    description: 'Ekana Technologies',
    buttonText: 'KNOW MORE',
    buttonLink: '/contact'
  }
]


const image1 = `assets/images/bg/bg-image-24.webp`

// const image2 = `/assets/images/bg/image_page-1.png`

const image3 = `/assets/images/bg/bg-image-22.webp`


const image6 = `assets/images/bg/bg-image-23.webp`



// const image8 = `assets/images/bg/bg-image-9.jpg`


// const PortfolioList = [
//   {
//     image: 'image-34',
//     category: 'SCHOOL MANAGEMENT SYSTEM',
//     title: 'School Management System',
//     linkUrl: "http://ekanatechnologies.in/products/sms/"
//   },
//   {
//     image: 'image-35',
//     category: 'Basically proposes a use, processing, and storage',
//     title: 'Smart Hospital',
//     linkUrl: "http://ekanatechnologies.in/products/smart_hospital/site/userlogin"
//   },
//   {
//     image: 'image-36',
//     category: 'Performance Improvement of an Industry by using (MRP) Software ',
//     title: 'Online Pharmacy Store',
//     linkUrl: "https://www.ekanatechnologies.in/products/pharma/Admin_dashboard/login"
//   }
// ]


const title = 'Why Ekana Technologies?'
const description = '  We study, practice, develop, and continuously sharp ourselves on all of the latest technologies to stay up to date. Also, we have some smart people who have years of experience in the field of web development and they love what they do.  Ekana Technologies is the best Website development company that specializes in web design and custom web development, application development and digital marketing. Ekana Technologies is a well-known IT company with experience in web development, custom web development, android application development, WordPress Development, Laravel Development, Magento development, and digital marketing. We have a wide list of globally satisfied client list for our quality and smart work. We provide our services for web development full time, part-time or as per your requirement. Being the best Web Development Company, we are experts in developing a simple single static page or complex web application or any kind of eCommerce Development. We are also expert in content management systems (CMS) like WordPress, Magento, Joomla, Open-cart, etc. ';


const Paralax = () => {
  const PostList = BlogContent.slice(0, 3);
  // const ServiceContent = ServiceList.slice(0 , 3);

  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowPopup((prevShowPopup) => !prevShowPopup);
  //   }, 1500); // Set the delay here (in milliseconds)

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [showPopup]);



  return (
    <Fragment>
      <Helmet
       pageTitle=" Best Digital Marketing Company in Lucknow " 
       metaKeywords=" best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, wordpress development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
       metaDescription="If you are searching for the Best Digital Marketing  Company in Lucknow Ekana Technologies is a Top Digital Marketing and Software Development  Company at a low price"
       canonical="/"
      />
     
      <div className="rn-page-title-area  ptb--30  bg_image bg_image--10"  >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner popup-image">

                <div id="float">
                  <img 
                   width="100%" height="auto" loading="lazy"
                  src="assets/images/logo/main-slider-slide-01-image-02.webp"
                    alt="We Are Here to Make Your Idea's" 
                    
                    />
                </div>
                <div id="float-1">
                  <img width="150" height="153" loading="lazy"  src="assets/images/logo/big-logo.webp" alt="We Are Here to Make Your Web Applications" />
                </div>
                {/* <div className="float-3">
                  <img style={{width: "340px",maxHeight:"200px"}}   src="assets/images/portfolio/portfolio3.png"  alt="" />
                </div> */}
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row sercice-details-content align-items-center">
                    <div className="col-lg-7 col-md-12 col-12 order-2 order-lg-1">
                      {SlideList.map((value, index) => (
                        <div className="slide slide-style-2  d-block align-items-left justify-content-center" key={index}>
                          <div className={`inner ${value.textPosition} mt-2`}>
                            {value.category ? <span>{value.category}</span> : ''}
                            {value.description ? <p className="description">{value.description}</p> : ''}
                            {value.title ? <p className="title">{value.title}</p> : ''}
                         
                             </div>
                             <div className="slide-btn mb--30"><Link className="rn-button-style--2 btn-solid" to={`/Best-IT-company-In-Lucknow`}>KNOW MORE</Link></div> 
                        </div>
                      ))}

                    </div>
                    <div className="col-lg-5 col-md-12 col-12 order-1 order-lg-2">
                 
                        {/* <img className="w-100" src="assets/images/bg/2324840-copy.png" alt="Service Images" /> */}
                        <ContactOne />



                 
                    </div>
                  </div>
                  {/* End Single Area */}

                </div>
              </div>
        
                        
            </div>
          </div>
        </div>
      </div>



      {/* Start Slider Area   */}
      {/* <div className="slider-wrapper bg_color--7">
    
        {SlideList.map((value, index) => (
          <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
            <div className="container">
              <Row>
                <Col md={7} xs={12}>
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ''}
                    {value.title ? <h2 className="title theme-gradient">{value.title}</h2> : ''}
                    {value.description ? <p className="description">{value.description}</p> : ''}
                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                  </div>
                </Col>
                <Col md={5} xs={12}>

                  <img src={`assets/images/bg/2324840-copy.png`} alt="" />

                </Col>
              </Row>

            </div>
          </div>
        ))}
      </div> */}
      {/* End Slider Area   */}

      {/* Start Page Wrapper */}
      {/* <div className="rn-service-details ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner popup-image">
                <div className="inner">
            
                  <div className="row sercice-details-content align-items-center">
                    <div className="col-lg-6 col-12 order-2 order-lg-1">
                      {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                          <div className={`inner ${value.textPosition}`}>
                            {value.category ? <span>{value.category}</span> : ''}
                            {value.title ? <h2 className="title theme-gradient">{value.title}</h2> : ''}
                            {value.description ? <p className="description">{value.description}</p> : ''}
                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                          </div>
                        </div>
                      ))}

                    </div>
                    <div className="col-lg-6 col-12 order-1 order-lg-2">
                      <div className="popup-image">
                        <img className="w-100" src="assets/images/bg/2324840-copy.png" alt="Service Images" />
                  
                      </div>
                    </div>
                  </div>
          

                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End Page Wrapper */}


      {/* Start Columns Area  */}
      {/* <Parallax className="" bgImage={image2} strength={50}>
      </Parallax> */}
      <div className="container">
        <div className="rn-about-area ptb--30">
          <div className="rn-columns-area ptb--60 ">
            <Zoom bottom>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-column">
                      <div className=" text-center">

                        <h1 className="title">Best Digital Marketing Company In Lucknow</h1>
                        <h2 className="title">What We Do?</h2>
                        <p className="titles">Effective solution for every businesses</p>


                      </div>
                      <p>Ekana technologies is not just Best Software Development or Best Web development company in Lucknow, we are a Best digital agency that believes in
                        innovative, quality delivery within the time with Brand Development. We just focus only on Research, Innovation, and Customization
                        and we always focus on performance by following these three keynotes. We understand the actual requirement and expectation of business
                        and our motive is to deliver the real value for the money within the time by filling the needs of clients. We have a dedicated team for
                        each segment of the web development and designing. Ekana Technologies having a team of those people who follow best web designing and best development
                        as a passion. Ekana Technologies offers all kinds of best custom web development, best eCommerce Development, best WordPress development, best WordPress fixing,
                       best mobile app development, and best digital marketing services for individuals and small to large scale companies. We always sharp our skills by
                        learning the latest and trending technologies & methodologies. Be a prominent part of the web development industry, our objective is to deliver
                        the high-end solution with high performance and an extreme level of security.
                      </p>
                    </div>
                  </div>

                </div>
              </div>

            </Zoom>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="container mt-2 ">
          <div className="row">
            <div className="col-lg-12">
            <div className="single-column">
              <div className=" text-center mb--30 mb_sm--0">
              <p className="titles">We turn your vision into a success story</p>
              </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-column">

                <h3>Fresh Ideas</h3>
                <p>Our capability is in providing innovative tech solutions for business thoughts according to your perception. Starting from a flexible and convenient layout to the multipurpose settlement of structures, we have an enhanced accumulation of designs that accommodate your creative endeavors.</p>
                <h3>Unique Designs</h3>
                <p>Our design structure supports the key methodology of the people in business by featuring the knowledge and motivation of the plan to accomplish special desires and objectives.</p>
                <h3>Organized Implementation</h3>
                <p>Organized Implementation is the most conspicuous procedure of incorporating a recently developed technically specialized software solution for a business where the standardized MVC format is followed which intensely relies upon the architecture of the solution. Our methodology is to make the created programming software adaptable, bug-free and smooth so that it supports all the platforms.</p>
                <h3>Target-Oriented Solution</h3>
                <p>Our aim is to design and develop best software solutions according to the set of objectives you follow in your business. As a software development company, our entire focus is on deploying best software solutions that resemble the business approach.</p>


              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-column">
                <Flywheel />


              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Ekana End*/}
      <div className="rn-about-area ptb--30 mt--30 mb--30">

        <div className="rn-about-wrapper">
          <div className="container">

            <div className="row row--35 align-items-center">

              <div className="col-lg-12">
                <Zoom bottom>
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title-1 text-center ">{title}</h2>
                      <p className="description">{description}</p>
                    </div>

                  </div>
                </Zoom>
              </div>
              {/* <div className="col-lg-6">
        <Zoom bottom>
          <div className="thumbnail">
            <img className="w-100" src="assets/images/logo/why-image-2.jpg" alt="About Images" />
          </div>
        </Zoom>
      </div> */}
            </div>

          </div>
        </div>

      </div>
      {/* <Parallax className="" bgImage={image2} strength={50}>

       
      </Parallax> */}
      {/* Start Service Area  */}
      <Parallax className="rn-paralax-service" bgImage={image1} strength={1000}>
        <div className="service-area ptb--120">

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">

                <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                  <Zoom bottom>
                    <h2>Our Service</h2>
                  </Zoom>
                  <Zoom bottom>
                    <p>
                      We provide custom software development solutions to make your organization
                      more capable to compete in this digital world. Ekana Technologies is a
                      leading software development company based in Lucknow, India. We design and
                      develop easy to use custom software that maps to your business processes.</p>
                  </Zoom>
                </div>

              </div>

            </div>
            <ServiceList item="18" column="col-lg-4 col-md-6 col-sm-6 col-12" />
          </div>

        </div>
      </Parallax>
      {/* End Service Area  */}

      {/* Start Service Area  */}
      {/* <Parallax className="rn-paralax-portfolio" bgImage={image2} strength={500} >
        <div className="portfolio-area ptb--120" data-black-overlay="6">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                  <Zoom bottom>
                    <h2>Our Works</h2>
                  </Zoom>
                  <Zoom bottom>
                    <p>Our dynamic Web Designing and Development Services help create your online business presence and maintain your brand.</p>
                  </Zoom>
                </div>
              </div>
            </div>
            <div className="row">

            <Sliderone/>
           
            </div>
          </div>
        </div>
      </Parallax> */}
      {/* End Service Area  */}


      {/* Start CounterUp Area */}
      <Parallax className="rn-counterup-area rn-paralax-counterup" bgImage={image6} strength={1000} >
        <div className="counterup-area ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                  <Zoom bottom>
                    <h3>We Are Experienced & Expertise</h3>
                  </Zoom>

                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
      </Parallax>
      {/* End CounterUp Area */}

      {/* Start Testimonial Area */}
      <Parallax className="rn-testimonial-area rn-paralax-testimonial  rn-testimonial-light"  >
        <div className="testimonial-area bg_color--7 " >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb--30 mb_sm--0 mt--30">
                  <Zoom bottom>
                    <h2>TESTIMONIALS</h2>
                  </Zoom>
                  <Testimonial />
                </div>
              </div>
            </div>

          </div>

        </div>
      </Parallax>
      {/* End Testimonial Area */}

      {/* Start Blog Area */}
      {/* <Parallax className="rn-blog-area rn-testimonial-light" bgImage={image8} strength={700} >
      

      </Parallax> */}
      <div className="blog-area ptb--120 bg_color--5 " >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                <Zoom bottom>
                  <h2>Our Latest Blogs</h2>
                </Zoom>
                <Zoom bottom>
                  <p>We regularly convey the best stories in our blog which are informative, entertaining and essential for you.</p>
                </Zoom>
              </div>
            </div>
          </div>
          <div className="row">
            {PostList.map((value, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <div className="blog blog-style--1 mb--0 mt--40">
                  <div className="thumbnail">
                    <a href="/blog-details">
                      <img width="100" height="300" loading="lazy" src={value.images} alt="Blog " />
                    </a>
                  </div>
                  <div className="content">
                    <p className="blogtype">{value.category}</p>
                    <h3 className="title"><a href="/blog-details">{value.title}</a></h3>
                    <div className="blog-btn">
                      <a className="rn-button-style--2 rn-btn-small" href="/blog-details">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* End Blog Area */}



      {/* Start Brand Area */}
      <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={image3} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>

      </Parallax>
      {/* End Brand Area */}




   
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={50}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                



    </Fragment>
  )
}

export default React.memo(Paralax);