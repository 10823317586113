import React from 'react'
import PageHelmet from "../../component/common/Helmet";
// import Breadcrumb from "../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../component/common/DiscussProject';
// import Header from "../../component/header/Header";
// import Footer from "../../component/footer/Footer";

const ReactWeb = () => {
  return (
    <React.Fragment>
    <PageHelmet 
    
    pageTitle='Best React  Development Company in Lucknow | React JS' 
            
    metaKeywords="Best React  Development in lucknow"
  
    metaDescription=" If you are searching for the Best React Web App Development Company in Lucknow Ekana Technologies is a Top React  Development and Software Development Company in Lucknow."
  
    canonical="/react-js"
    
    />

    {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
    {/* Start Breadcrump Area */}
    {/* <Breadcrumb No={1} parent={"Services"}  title={'React JS'}   /> */}
    {/* End Breadcrump Area */}



   



  {/* Start Page Wrapper */}
  <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                     


                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                          
                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                               </div>
                                            </div>
                                         
                                        </div>
                                        {/* End Single Area */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}


 

        <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>


   

    {/* Start Back To Top */}
    <div className="backto-top">
        <ScrollToTop showUnder={160}>
            <FiChevronUp />
        </ScrollToTop>
    </div>
    {/* End Back To Top */}
    

</React.Fragment>
  )
}

export default React.memo(ReactWeb);

