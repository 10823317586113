import React from 'react'
import styled from 'styled-components';

const Project = (props) => {
    const { disc, person } = props.item;
    return (
        <Container className='project'>
            <div className="disc">


                <div className='testmonial-1'>
                    <div className='testmonial-content'>{disc}</div>
                    <p>{person}</p>
                </div>



            </div>


        </Container>
    )
}

export default Project;

const Container = styled.div`
width: 554PX;
height:230PX;
background: #ffffff;
border-radius: 30PX 54PX 54PX 30PX;
    z-index:999;
    margin: 0 1rem;
    left: -110px;
    padding: 0.5rem;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  
    .disc{
   
        text-align: left;
        padding: 0.5rem;
        h1{
            font-size: 2rem;
            color:#000
        }
    
      
    }

    .testmonial-1{
        background: #ffffff;
        width: 524PX;
        height: 200PX;
        padding: 25PX 25PX 0 30PX;
        margin-top:5px;
        border-radius: 30PX 30PX 30PX 30PX;
        position: absolute;
        transition: all .7s;
        p{
            font-size:18px;
            color:#000;
             font-family:Roboto;
            font-weight:900;
            margin-left:200px;
        }
        
    }
    
    @media(max-width :600px){
        width: 354PX;
height:300PX;
background: #ffffff;
border-radius: 30PX 54PX 54PX 30PX;
    z-index:999;
    margin: 0 1rem;
    left: 0px;
    padding: 0.5rem;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  
    .disc{
   
        text-align: left;
        padding: 0.5rem;
        h1{
            font-size: 2rem;
            color:#000
        }
    
      
    }

    .testmonial-1{
        background: #ffffff;
        width: 324PX;
        height:300PX;
        padding: 25PX 25PX 0 30PX;
        margin-top:5px;
        border-radius: 30PX 30PX 30PX 30PX;
        position: absolute;
        transition: all .7s;
        p{
            font-size:18px;
            color:#000;
             font-family:Roboto;
            font-weight:900;
            margin-left:200px;
        }
        
    }
    }
    

`