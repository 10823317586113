import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const CrmDevelopment = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            
            pageTitle='The Best CRM Development  Company in Lucknow'
            metaKeywords="the best crm development company in lucknow, best product development company in Lucknow,  website creators "
            metaDescription=" If you are searching for the Best Product Development Company in Lucknow Ekana Technologies is a Best Product Development  Company in Lucknow at low price "
            canonical="/The-Best-CRM-Development-Company-in-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={7}  parent={"Services"}  title={' CRM Development'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>CRM solutions in the Customer Relationship Management is a category of enterprise software that covers a broad set of applications and software designed to help businesses manage customer data and customer interaction, access business information, automate sales, marketing, customer support along with the management of the employee, vendor, and partner relationships.</p>
                                                <p>The business-customer relationship is commonly managed by the CRM system along with the management of the business contacts, clients, contract wins and sales leads. CRM software is highly scalable and customizable, allowing businesses to gain actionable customer insights with a back-end analytical engine, view business opportunities with predictive analytics, streamline operations and personalize customer service based on the customer's known history and prior interactions with your business.</p>

                                                <h4 className="title">Our Customer Relationship Management solution enables:</h4>

                                                <ul className="liststyle">

                                                    <li>Account Management</li>
                                                    <li>Lead and Opportunity Management</li>
                                                    <li>Contacts Management</li>
                                                    <li>Quotes and Proposal Management</li>
                                                    <li>Team Selling Support</li>
                                                    <li>Sending and Receiving emails</li>
                                                    <li>Sales Lead & Support Call Dispatch</li>
                                                    <li>History actions</li>
                                                    <li>Problem Management</li>
                                                </ul>

                                                <p className='mt-3'>The main focus of a CRM system is to ensure that the needs of the customer are met in a timely and efficient manner so that customer satisfaction is maintained at a high level throughout the organization which will help the company meet its revenue and profitability goals.</p>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}






                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}





            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(CrmDevelopment);
