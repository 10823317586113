import React from 'react'
import { Parallax } from 'react-parallax';
import { Zoom } from 'react-reveal';
import BrandTwo from '../BrandTwo';

const PrivacyPolicy = () => {
    return (
        <React.Fragment>

            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Privacy Policy for Ekana Technologies</h4>
                                                    <p>At ekanatechnologies, accessible from www.ekanatechnologies.ca, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by ekanatechnologies and how we use it.</p>
                                                    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
                                                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in ekanatechnologies. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Free Privacy Policy Generator.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Consent</h4>
                                                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Information we collect</h4>
                                                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                                                    <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                                                    <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>


                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">How we use your information</h4>
                                                    <p>We use the information we collect in various ways, including to:</p>
                                                    <ul className="liststyle">
                                                        <li>Provide, operate, and maintain our website</li>
                                                        <li>Improve, personalize, and expand our website</li>
                                                        <li>Understand and analyze how you use our website</li>
                                                        <li>Develop new products, services, features, and functionality</li>
                                                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                                        <li>Send you emails</li>
                                                        <li>Find and prevent fraud</li>
                                                    </ul>


                                                </div>


                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}

                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">WordPress Fixing services or WordPress security fixes</h4>
                                                    <p>If a WordPress website is important to your business, then your most important need is most likely going to be the peace of mind. While you concentrate on your business, you would want the website to run smoothly. If your website is based on the subscription, it’s even more critical to have the uptime monitored, and the site backed up regularly. Maintaining a website can be a time-sink task if the necessary skills, available bandwidth, and resources are not available to you.</p>
                                                    <p>To run a WordPress website run successfully, a lot of work has to be done both on the front-end and the back-end. Regular update is the key to keep the site ticking over, running at peak performance and most importantly, remaining secure from hackers and malicious scripts. WordPress website has been an easy target for security exploits, not because it’s vulnerable but out of its sheer popularity. Because of its popularity, it has become a very important aspect to maintain and update the WordPress website.</p>
                                                    <p>As a WordPress security provider, we take care of the constant WordPress core, plugin and theme updates, backups, optimization and development.</p>
                                                    <h5 className='mt-3'>We perform the following Security Checks and Updation Activities:-</h5>
                                                    <ul className="liststyle">
                                                        <li>Upgrading WordPress Core, Plugins and Themes</li>
                                                        <li>Server and Uptime Monitoring</li>
                                                        <li>Versioning of code and Backups</li>
                                                        <li>Content Updates and SEO Analysis</li>
                                                        <li>Conversion Optimization, Performance Optimization, and Evaluation</li>
                                                        <li>WordPress bug fixes</li>

                                                    </ul>

                                                    <h5 className='mt-3'>Why Ekana Technologies?</h5>
                                                    <p className='mb-2'>With our w3c standards, we are writing coding and giving the best output, we can ensure the following for your WordPress websites:</p>
                                                    <ul className="liststyle">

                                                        <li>Theme customization</li>
                                                        <li>Integrate any web form of design</li>
                                                        <li>WordPress PHP Code customization</li>
                                                        <li>Customized WordPress Design</li>


                                                    </ul>
                                                    <p className='mt-2 mb-2'>Our developers having expertise in WordPress plugin development and WordPress custom development and having experience and ability to deliver the best WordPress website design and development project.</p>

                                                    <ul className="liststyle">

                                                        <li>Custom web-based applications built using WordPress</li>
                                                        <li>WordPress technical support</li>
                                                        <li>WordPress theme (template) creation</li>
                                                        <li>Custom WordPress plugin development</li>
                                                        <li>Custom WordPress theme design</li>
                                                        <li>Migration from other blogging systems to WordPress</li>
                                                        <li>WordPress server and domain migration</li>



                                                    </ul>

                                                </div>
                                            </div>

                                        </div>
                                    </Zoom> */}
                                    {/* End Single Area */}


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Log Files</h4>
                                                    <p>ekanatechnologies follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Cookies and Web Beacons</h4>
                                                    <p>Like any other website, ekanatechnologies uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Advertising Partners Privacy Policies</h4>
                                                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of ekanatechnologies.</p>
                                                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on ekanatechnologies, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                                                    <p>Note that ekanatechnologies has no access to or control over these cookies that are used by third-party advertisers.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Third Party Privacy Policies</h4>
                                                    <p>ekanatechnologies's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                                                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
                                                    <p>Under the CCPA, among other rights, California consumers have the right to:</p>

                                                    <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>

                                                    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                                                    <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>

                                                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>


                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">GDPR Data Protection Rights</h4>
                                                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                                                    <p>The right to access - You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                                                    <p>The right to rectification - You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                                                    <p>The right to erasure - You have the right to request that we erase your personal data, under certain conditions.</p>
                                                    <p>The right to restrict processing - You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                                                    <p>The right to object to processing - You have the right to object to our processing of your personal data, under certain conditions.</p>
                                                    <p>The right to data portability - You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                                                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Children's Information</h4>
                                                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                                                    <p>ekanatechnologies does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             {/* Start Brand Area */}
             <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo/>
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>
            {/* End Brand Area */}
        </React.Fragment>
    )
}

export default PrivacyPolicy;
