import React from "react";
import { Helmet } from 'react-helmet-async'






const PageHelmet = (props) => {

    const {pageTitle,canonical,metaKeywords,metaDescription} = props


    return (
        <React.Fragment>
            <Helmet>
                <title> {pageTitle} </title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <link rel='canonical' href={`https://ekanatechnologies.com${canonical}`} />
                <meta name="keywords" content={metaKeywords} />
                {/* <meta name="title" key="title" content={props.pageTitle} /> */}
                <meta property="og:title" key="og:title" content={pageTitle} />
                <meta name="description" key="description" content={metaDescription} />

                <meta property="og:locale" key="og:locale" content="en_US" />
                <meta charSet="utf-8" />
                <meta property="og:type" key="og:type" content="website" />
                <meta property="og:type" content="website" />

                <meta property="og:url" content={`https://ekanatechnologies.com${canonical}`} />
                <meta
                    property="og:description"
                    key="og:description"
                    content={metaDescription}
                />

            </Helmet>
        </React.Fragment>
    )
}


export default React.memo(PageHelmet);
