import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
// import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Magento = () => {
  return (
    <React.Fragment>
            <PageHelmet  
            pageTitle='Best Magento Development Company in Lucknow | Magento Development'         
            metaKeywords="Best Magento Development in lucknow" 
            metaDescription=" If you are searching for the Best Magento Development Company in Lucknow Ekana Technologies is a Top Magento Development and Software Development Company in Lucknow." 
            canonical="/magento-development"
            
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            {/* <Breadcrumb No={5} parent={"Services"}  title={'Magento Development'} /> */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-12 col-12">
                                            <div className="thumb">
                                                <img   loading="lazy" style={{ width:"200px",maxHeight:"400px",marginLeft:"42%"}} src="https://ekanatechnologies.com/wp-content/uploads/2020/02/magento-development.jpg" alt="Service Images" />
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                               <p>Ekana Technologies identify your pre-requisites, business preferences and develop simply as well as complex web processes accordingly, by using supportive frameworks. We come up with an e-commerce strategy that is executable and in sync with the rudiments of your brand. Our Magento development solutions establish an end-to-end e-commerce shopping portal with dynamic features to develop catalogs and comprehensive product listings. Magento solutions of Ekana Technologies help you create, amend, delete products/categories thereby maintaining your inventory and sales effectively.</p>
                                               <p>Ekana Technologies understands the core functionality of the business organization and provides full-cycle offshore Magento customization services including Magento custom module, theme designing, consultation, extension development, support and maintenance, enterprise and professional Magento solutions.</p>
                                               <p>We help organizations to build their business portals with diverse, integrated modules and deploy solutions that fasten the business processes to match the pace of today’s business world.</p>
                                              
                                                <h4 className='mt-3 mb-3'>Magento Development Key Benefits</h4>
                                                <ul className="liststyle">
                                                <li>Simplify the buying process.</li>
                                               <li>Categorize customers according to buying history, preferences and demographic criteria.</li>
                                               <li>No risk of losing data.</li>
                                               <li>Reduced development cost and saves time.</li>
                                               <li>Proffer persuasive and safe shopping experience to customers.</li>
                                               <li>Develop custom coupons to enhance conversions.</li>
                                               <li>Easy customization.</li>
                                               <li>It allows the customers to compare products and review them.</li>
                                               <li>Better user-experience with multi-shipping and single transaction functionality.</li>
                                               <li>SEO friendly e-store causing increased ROI.</li>
                                                </ul>
                                                

                                               
                                               

                                                <h5 className='mt-3 mb-3'>Ekana Tehnologoes Magento Development Services</h5>
                                                <ul className="liststyle">
                                                <li>E-Commerce development.</li>
                                                <li>Extension development.</li>
                                                <li>Module development.</li>
                                                <li>Multi-store development.</li>
                                                <li>Data migration/maintenance.</li>
                                                <li>Template customization.</li>
                                                <li>Theme development.</li>
                                                </ul>
                                                <h4 className='mt-3 mb-3'>Why Ekana Tehnologies?</h4>
                                                <ul className="liststyle">
                                                <li>We proffer Magento development services at competitive pricing without compromising the quality of output produced.</li>
                                                <li>Scalable Magento shopping cart solutions of Ekana Technologies help organizations to have a large product inventory, selling a huge number of customizable complex products.</li>
                                                <li>Customization being our forte enables our developers to assist clients with any modules extensions or system integration.</li>
                                                <li>We maintain constant conversation sessions with clients via chat, phone, and e-mail.</li>
                                                <li>We create all types of virtual shops including B2B online store or B2C shopping cart based websites.</li>
                                                </ul>
                                             </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}

            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
  )
}

export default React.memo(Magento);
