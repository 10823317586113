const BlogContent = [
    {
        images: 'assets/images/blog/wordpress.webp',
        title: ' How to Successfully Implement Digital Transformation for Your Business',
        category: 'Ekana Technologies',
        link:"https://ekanatechnologies.com/how-to-successfully-implement-digital-transformation-for-your-business/"
    },
    {
        images: 'assets/images/blog/transformation-your-business-.webp',
        title: 'Importance of a Web Design',
        category: 'Ekana Technologies ',
        link:"https://ekanatechnologies.com/importance-of-a-web-design/"
    },

    {
        images: 'assets/images/blog/mobile-application.webp',
        title: 'What is Coronavirus, symptoms, and how to protect yourself from Coronavirus?',
        category: 'Ekana Technologies ',
        link:"https://ekanatechnologies.com/what-is-coronavirus-symptoms-and-how-to-protect-yourself-from-coronavirus/"
    },
    {
        images: 'assets/images/blog/transformation-your-business-.webp',
        title: ' What to choose - Web Application or Mobile Application',
        category: 'Ekana Technologies ',
        link:"https://ekanatechnologies.com/what-to-choose-web-application-or-mobile-application/"
    },
    {
        images: 'assets/images/blog/web-ui-design.webp',
        title: 'WordPress Website Bug Fixing & Development - Ekana Technologies',
        category: 'Ekana Technologies ',
        link:"https://ekanatechnologies.com/wordpress-website-bug-fixing-development-ekana-technologies/"
    },
    {
        images: 'assets/images/blog/wordpress.webp',
        title: 'Web Application Development Trends',
        category: 'Ekana Technologies ',
        link:"https://ekanatechnologies.com/web-application-development-trends/"
    },

    
]

export default BlogContent;