import React from "react";
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
// import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/header/Header";
// import Footer from "../component/footer/Footer";
// import TeamOne from "../blocks/team/TeamOne";
import { Parallax } from "react-parallax";


const About = () => {


    return (
        <React.Fragment>
            <PageHelmet
                pageTitle='Best IT Company in Lucknow '
                metaKeywords="Best IT  Development company in lucknow,Best Sortware Development Company in Lucknow"
                metaDescription="If you are searching for the Best IT Company in Lucknow Ekana Technologies  is a Top Digital Marketing and Software Development  Company in Lucknow at low price"
                canonical="/Best-IT-company-In-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}

            <Breadcrumb No={11} title={'About Us'} />

            {/* End Breadcrump Area */}

            {/* Start About Area  */}




            <div className="rn-blog-area  bg_color--1">

                <div className="container">

                    <div className="row row--5 align-items-center">


                        <div className="col-lg-12 ptb--60">
                            <Zoom bottom>
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h1 className="title text-center"> Visualize Your Ideas Into Reality With The Best IT Service Company</h1>
                                    </div>

                                </div>
                            </Zoom>
                        </div>
                    </div>

                </div>
            </div>

            <div className="rn-blog-area ptb--120 bg_color--1">

                <div className="container">

                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5">
                            <Zoom bottom>
                                <div className="blog blog-style--1">
                                    <div className="thumbnail">
                                        <img width="100%" height="auto" loading="lazy" src="assets/images/about/about-3.webp" alt="About Images" />
                                    </div>
                                </div>
                            </Zoom>
                        </div>

                        <div className="col-lg-7">
                            <Zoom bottom>
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About Us</h2>
                                        <p className="description">If you wish to create a digital road-map for your business, it is vital for you to implement smart and effective best web technologies & best digital marketing services for enhanced outcomes. Best Digital marketing in todas's era is the best ploy to advertise a business across the globe. If you have expertise in publicizing your business, you have the best chance to earn higher revenues. For a newbie in digital marketing, we at Ekana Technologies are there to lend a helping hand. Our prime goal is to outdo the expectations of every client by offering outstanding customer service, improved flexibility, and greater value for their money, hence enhancing system functionality and augmenting operation expertise.</p>
                                    </div>
                                    <div className="row mt--30">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">

                                                <p>Ekana Technologies offers innovative Best digital marketing, Best web development and Best design services along with the app development services, all under one roof. Our staple services include web designing and development, mobile apps development, custom application development, E-commerce website development, SEO and digital marketing services, in consort with a special feature of product branding. Through these remarkable services, we help businesses and companies to use high-end technology to their optimal advantage. We even are adept at creating custom websites using technologies, such as PHP, Node.JS. Being one of the best website development company, we take pride in offering commendable services to the extensive array of business with a client base all across the world. </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Zoom>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="about-inner inner">
                            <div className="row mt--30">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <Zoom bottom>
                                        <div className="about-us-list">

                                            <p>
                                                In today's era of the internet and mobile communication, one cannot afford to stay standoffish. If businesses want to upturn their revenue, they need to optimize their online presence. And this is what we are expertise in to do. We provide our customers with the best services, innovative products with meaningful solutions with the plus point of well-timed delivery at quite a reasonable cost.  We, at Ekana Technologies, use the most innovative approach to achieve our goal of quality services and client satisfaction. Our team comprises some experts in the industry and provides a harmonious environment where you can explore the vision within yourself and rise above the boundaries.
                                            </p>
                                        </div>
                                    </Zoom>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area  */}

            {/* Start CounterUp Area */}
            <div className="rn-counterup-area pb--120 bg_color--1">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="section-title text-center">
                                <h2 className="fontWeight500">We Are Experienced & Expertise</h2>
                            </div>

                        </div>
                    </div>
                    <div className="counterup-area ptb--120 bg-theme-gradient theme-text-white">
                        <div className="container">
                            <CounterOne />
                        </div>
                    </div>
                </div>

            </div>
            {/* End CounterUp Area */}

            {/* Start Finding Us Area  */}
            <div className="rn-finding-us-area rn-finding-us bg_color--1">

                <div className="inner">

                    <div className="content-wrapper">
                        <Zoom bottom>
                            <div className="content">
                                <h3 className="theme-gradient">We Are Here to Make Your Idea</h3>
                                <p>
                                    We Are Experienced & Expertise in Web Development, Software Development, CRM Software, ERP systems, eCommerce Development, CMS Development, Custom Web Development, WordPress Development, Mangento Development, Web Designing, Digital Marketing.
                                </p>
                                <a className="rn-btn btn-white" href="/contact">Get Started</a>
                            </div>
                        </Zoom>
                    </div>

                    <div className="thumbnail">
                        <div className="image">
                            <img width="100%" height="auto" loading="lazy" src="assets/images/about/finding-us-01.webp" alt="Expertise in Web Development in Lucknow" />
                        </div>
                    </div>
                </div>

            </div>
            {/* End Finding Us Area  */}

            {/* Start Team Area  */}
            {/* <div className="rn-team-wrapper ptb--50 bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                        
                            <div className="row">
                            <Zoom bottom>
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Our Skilled Team</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                                </Zoom>
                            </div>
                            
                            <TeamOne column="col-lg-3" teamStyle="team-style--bottom" item="8" />
                        </div>
                    </div>
                </div> */}
            {/* End Team Area  */}

            {/* Start Testimonial Area */}
            {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div> */}
            {/* End Testimonial Area */}

            {/* Start Brand Area */}
            {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* End Brand Area */}

            {/* Start Brand Area */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>
            {/* End Brand Area */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )

}
export default React.memo(About);