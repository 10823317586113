import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ServiceOne from './service/ServiceOne';
import Helmet from '../component/common/Helmet';

import PlacementBrand from './companybrond/PlacementBrand';
// import { Parallax } from 'react-parallax';
// import ServiceTwo from './service/ServiceTwo';
// import Breadcrumb from './common/Breadcrumb';


const IndustrialTraining = () => {

    return (
        <React.Fragment>
            <Helmet pageTitle="Industrial Training" />
            <main className="page-wrapper">
                {/* <Breadcrumb No={5} title={'Industrial Training'}   /> */}
                <div className="wrapper pt--30 pb--30 "  >
                    <div className="container">

                        <div className="rn-columns-area  ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12 col-12">
                                        <div className="single-column">
                                            <h1 className="tilte">Training the way you want </h1>
                                            <p>Ekana Technologies Software Training and certifications offers you the ability to earn credentials to demonstrate your expertise. It is designed to validate your skills and capability to perform role-related tasks and activities at a specified level of competence. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12 col-12 mt_sm--30">
                                        <div className="single-column">
                                            <img width="100%" height="auto" loading="lazy" src="assets/images/portfolio/trainingcourses.png" alt="Industrial Training" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container">
                        <h2 className='text-center mb--30'>Why choose Ekana Technologies program?</h2>

                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-column custom-color ">
                                    <h4 className="tilte">Program Benefits </h4>
                                    <p>Hands-on projects design by Developer  </p>
                                    <p>
                                        <span>Ticket Tracker Application</span>
                                        <span>Employee Management System</span>
                                        <span>Pharmacy lab test book management system</span>
                                        <span>Efficient E-commerce Data Management Tool</span>
                                    </p>


                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mt_sm--30">
                                <div className="single-column custom-color custom-color--1">
                                    <h4 className="tilte">for freshers & recent graduates</h4>
                                    <p>Learn to kickstart a rewarding career in Full-Stack Software Development</p>
                                    <p>Choose between classroom learning at Lucknow, Chennai & Hyderabad centers or live online classes</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 mt_sm--30 mt_md--30">
                                <div className="single-column custom-color custom-color--2">
                                    <h4 className="tilte">Industry-relevant skills</h4>
                                    <p>Learn Core Java, DBMS, Data Structures & Algorithms, J2EE, HTML, CSS and more</p>
                                    <p>Learn Core JavaScript and Framework such as Angular and  React with the additional self-paced optional module</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120  ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-md-6 col-12">
                                <div className="single-column">
                                    <h4 className="tilte">Overview of Web design & Development Training Course</h4>
                                    <p>Our Web Designing & Development certification training provides a comprehensive overview of web design and development concepts, tools, and technologies. Students will learn to design and develop dynamic, responsive, and user-friendly websites that meet the latest industry standards. Our experienced trainers will guide you through the entire process, from wireframing and prototyping to coding and debugging.</p>
                                    <p>Through our certification training, you will gain hands-on experience using popular front-end development technologies such as HTML, CSS, and JavaScript, as well as back-end development technologies such as PHP, Python, and MySQL. You will also learn about web development frameworks such as Bootstrap, React, and AngularJS.</p>
                                    <p>Our training focuses on practical applications of web design and development concepts, including web development best practices, accessibility standards, and responsive design. By the end of the course, you will have developed a portfolio of web projects that demonstrate your proficiency in web development, positioning you for success in the industry.</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12 mt_sm--30">
                                <div className="single-column">
                                    <h4 className="tilte ">500+ Happy Learners Student Feedback</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}




                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120  ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="tilte">Benefits of learning Web design & Development</h4>
                                    <p>I'm sorry, but there is no direct relation between Data Science and Web Designing & Development. Data Science is a field that deals with extracting insights and knowledge from data, whereas Web Designing & Development is concerned with the creation and maintenance of websites.</p>
                                    <p>However, if you want to know the benefits of taking a Web Designing & Development certification course, here are a few:</p>
                                    <p>Gain in-depth knowledge of web development tools and technologies</p>
                                    <p>Develop the skills to create interactive and responsive web applications</p>
                                    <p>Understand the principles of user experience (UX) and user interface (UI) design</p>

                                    <p>Learn to optimize websites for search engines (SEO)</p>
                                    <p>Enhance your employability with a certification that demonstrates your expertise in web development.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}

                <div className="rn-columns-area ">

                    <ServiceOne />
                </div>

                {/* <ServiceTwo/> */}

                {/* Start Columns Area  */}
                <div className="rn-columns-area ptb--120 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-column">
                                    <h4 className="tilte">Related job roles</h4>
                                    <p>After the completion of the web design & development course here at Apponix, you can become a professional in the IT sector and your job role could be any one of the following -</p>
                                    <p>Front end web designer </p>
                                    <p>Back end web developer</p>
                                    <p>Web application Designer</p>
                                    <p>Design and layout analyst</p>
                                    <p>Web analyst</p>
                                    <p>Web Designer</p>
                                    <h4>For more details about our web design and development complete course get in touch with us!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Columns Area  */}

                <div className="wrapper pt--30 pb--30 "  >


                    <div className="rn-columns-area  ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="single-column">
                                        <p style={{color:"#e63024"}}>Change Your Career?</p>
                                        <h2 className="tilte">Change careers courses  Thinking about a career change? </h2>
                                    <p>We have a look at how you can go about changing your career, and take a dive into some of the best courses you can take if you're looking for a career change.</p>
                                    <p>If you're looking to develop new skills, and train to work in a new industry, you’re in the right place. From application writing crash courses, to courses with academic accreditation, we'll look at all the best courses to change your career. </p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-12 mt_sm--30">
                                    <div className="single-column">
                                        <img width="100%" height="auto" loading="lazy" src="assets/images/about/career.png" alt="Thinking about a career change in Development" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div className="brand-wrapper">
          <div className="container">
            <div className="row">
                <h2 className='text-left'>Our Alumini Working in</h2>
              <div className="col-lg-12">
              <PlacementBrand/>
              </div>
            </div>
          </div>
        </div>

         

            </main>


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}


        </React.Fragment>
    )
}

export default IndustrialTraining;
