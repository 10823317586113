import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const CustomWeb = () => {
    return (
        <React.Fragment>
            <PageHelmet

                  pageTitle='Best Custom Web Development Company in Lucknow' 
                  metaKeywords="Best Custom Web Development Company in Lucknow, wordpress development company in lucknow, best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
                
                  metaDescription=" If you are searching for the Best Custom Web Development Company in Lucknow Ekana Technologies is a Top Web Development and Software Development Company in Lucknow."
                  canonical="/Best-Custom-Web-Development-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={2}  parent={"Services"}  title={'Custom Web Development'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* End Single Area */}


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">

                                                    <p>All the factors should be taken into account while choosing a website developer. This is because website development requires a lot of skills to customize a client website that best fits his business purpose. Various factors that play an important role in hiring a developer who can customize a client website may include:-</p>
                                                    <p>Designing which includes PHP module, HTML5 or a simple CSS module. HTML5 is for showing high-end graphics and animation, CSS is used for single-ended user interface while PHP is used for the multi-login purpose.</p>
                                                    <p>A choice between HTML code, JavaScript code, and iframe code. HTML code is a code for making the included sub-page interactive and automated, JavaScript code is used for enabling user interface whereas the iframe is used to include an entire page within a webpage.</p>
                                                    <p>Use of the keywords in the website so that the website is available at the search engines, backlinks that are directed towards the website from other webpages with different URLs etc.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}



            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>





            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(CustomWeb);
