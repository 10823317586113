import React, { useRef } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import styled from 'styled-components';


let data = [
    {
       
        person:"Matt Pearson",
        disc : "It has been a blessing to work with Ekana Technologies; they have exceeded our expectations in every way. Their services are straightforward, honest, take care of all your needs quickly, reliable, you can count on them."
    },
    {
      
        person:"Vibeka Wren",

        disc : "It is evident from the unrivaled level of customer service they provide and their attentiveness to our specific needs that they are passionate about what they do."
    },
    {
 
        person:"Marci Wiersma",

        disc : "The Ekana Technologies team is fantastic; we have been working with them for almost a year. Through their efforts, we have been able to accomplish every objective we have set for our website and online presence."
    },
    {
      

        person:"Matt Pearson",

        disc : "I believe, any engineering team should be able to understand the requirements, deliver on time, deliver according to the requirements. With Ekana we have found the team that can provide all three. It's a great team with excellent communication skills."
    },
    {
    
        person:"Marci Wiersma",
        disc : "We had a brilliant experience working with Ekana. They delivered great solutions using cutting edge techniques. The quality of their work was excellent and was all delivered on time. The team was a pleasure to work with."
    }
];

var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode : false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode : false
        }
      }
    ]
  };
const SliderComp = () => {
  const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
      {sliderProject}
      </Slider>
      <Buttons>
      <button 
        onClick={() => arrowRef.current.slickPrev()}
        className='back'></button>
      </Buttons>
      <Buttons2>
       
        <button 
        onClick={() => arrowRef.current.slickNext()}
        className='next'></button>
      </Buttons2>
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 50PX;
    height: 50PX;
    background: url(assets/img/backword.svg);
    border-radius: 50%;
    box-shadow: 0 10PX 20PX rgba(192,166,212,.15);
    position: absolute;
    cursor: pointer;
    top:30%;
    left: 31px;
  }

  .back{
    left: -1rem;
  }
`

const Buttons2 = styled.div`
  button{
    width: 50PX;
    height: 50PX;
    background: url(assets/img/backword.svg);
    border-radius: 50%;
    box-shadow: 0 10PX 20PX rgba(192,166,212,.15);
    position: absolute;
    cursor: pointer;
    top:30%;
    right: -15px;
    transform:scaleX(-1);
  }

  
`