import React from "react";


const BrandTwo = () => {
    return (
        <React.Fragment>
            <ul className="brand-style-2">
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-11.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-12.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-13.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-14.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-15.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-16.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/client-17.webp`} alt="brand" />
                </li>
                <li>
                    <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/client/ekanahospital.webp`} alt="brand" />
                </li>

            </ul>
        </React.Fragment>
    )
}


export default React.memo(BrandTwo);