import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Mlm = () => {
  return (
    <React.Fragment>
            <PageHelmet
            
            pageTitle='Best MLM Development Company in Lucknow' 
            
            metaKeywords="best web development company in lucknow, wordpress development company in lucknow, best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
          
            metaDescription=" If you are searching for Best MLM Development Company in Lucknow Ekana Technologies is a Top Web Development and Software Development Company in Lucknow."
          
            canonical="/Best-MLM-Development-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={2}  parent={"Services"}  title={'MLM Development'} />
            {/* End Breadcrump Area */}



            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>With the rapid change in the digital era, it has become important to change with time. Our company is a revolutionary evolution in the history of MLM development in recent years. Ekana technologies have the ability to eliminate all the worries that are related to the network in a very short span of time. We are a team who expertise in calculations and operation so that your business generates more accurate results, which will automatically outcomes in immense growth in your business. To overcome the present loopholes of available network marketing software, our company has designed software after doing proper research on the present scenario of the MLM business industry. We have designed various customized software that is related to various domains such as Binary Level plan, Matrix Plan, Level Plan, Single-leg Plan, Repurchase Plan. Our developer’s dedication to user’s information security and research and development has enabled us to provide high-end features in MLM software development business industry.</p>

                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}





            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>



            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
  )
}

export default React.memo(Mlm);
