import React from 'react';


const PlacementBrand = () => {
  return (
    <React.Fragment>
    <ul className="brand-style-2">
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/adobe.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-1.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-2.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-3.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-4.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-5.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-6.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-7.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-8.jpg" alt="brand"/>
        </li>
        <li>
            <img width="100%" height="auto" loading="lazy" src="assets/images/logo/placement-company-9.png" alt="brand"/>
        </li>
      
        
        
    </ul>
</React.Fragment>
  )
}

export default React.memo(PlacementBrand);