import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
// import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Laravel = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            
            pageTitle='Best Laravel Development Company in Lucknow | Ekana Technologies' 
            
            metaKeywords="Best Laravel Development in lucknow"
          
            metaDescription=" If you are searching for the Best Laravel Development Company in Lucknow Ekana Technologies is a Top Laravel Development and Software Development Company in Lucknow."
          
            canonical="/lavavel-development"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            {/* <Breadcrumb No={5} parent={"Services"}  title={' Laravel Development'} /> */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-12 col-12">
                                            <div className="thumb">
                                                <img  loading="lazy" style={{ width: "200px", maxHeight: "400px", marginLeft: "42%" }} src="https://ekanatechnologies.com/wp-content/uploads/2020/02/laravel-framework.jpg" alt="Service Images" />
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>Open source development is a specialty of Ekana technologies that involves working with scripting languages e.g. AJAX, Javascript, jQuery etc, server as Apache, database integration as MySql and OS primarily Linux. . We as a software outsourcing partner covers a wide area from basic website design and development to tailored PHP web application and E-Commerce shopping cart solutions including X-cart, Magneto, Prestashop, VirtueMart, CS cart and Zen cart.</p>
                                                <p>Our customized PHP development services are well-suited to cater multiple business requirements, thereby optimizing profits and rendering quality solutions. Developers at Ekana Tehnologies have hands on experience in diverse PHP frameworks such as Zend, Symfony, DIY, Codelgniter, CakePHP, Fusebox etc.</p>

                                                <p>PHP web solutions offered by Ekana Technologies comply with the changing business and marketing needs of clients from round the world.</p>

                                                <h4 className='mt-3 mb-3'>PHP Web Development Solutions</h4>
                                                <h5 className='mt-3 mb-3'>We offer wide range of bespoke PHP development services:</h5>
                                                <p>Social Networking Applications - Online communities, networking systems, Facebook and Myspace application development.</p>
                                                <p>Social Networking Applications - Online communities, networking systems, Facebook and Myspace application development.</p>
                                                <p>Content Management System - Custom CMS, Joomla, WordPress, Drupal.</p>
                                                <p>Online Business Application - CRM solution, Sales automation, Custom B2B, B2C, C2C, C2B solutions, Web mail, Scheduling, Calendaring applications, </p>
                                                <p>Mass mailing, QuickBooks integration, Order management, News letter management and Inventory solutions.</p>
                                                <p>Advanced PHP Programming - Forum, Blogs, Auctions, Classified, Discussion Board, Web 2.0 systems and blogging systems.</p>
                                                <p>Custom PHP Programming - Real Estate systems, Affiliate tracking systems and advertising based systems, Online booking & quote engine systems, </p>
                                                <p>Online multi-player games, statistics collection systems.</p>
                                                <h5 className='mt-3 mb-3'>Why Ekana Technologies? </h5>

                                                <ul className="liststyle">
                                                    <li>Backed with performance assurance, our functional PHP web solutions are proffered at competitive prices.</li>
                                                    <li>Our communication channels remain open (24 X 7) that helps connect you with the correct team of technical experts.</li>
                                                    <li>Flexible engagement models.</li>
                                                    <li>Deliver projects within the fixed timeframe and budget.</li>
                                                    <li>Accreditation from NASSCOM and Microsoft Gold certified Partner.</li>


                                                </ul>


                                                <p className='mt-3'>Ekana Tehnologoes’s functional experience, technological capabilities enabled it to work successfully on an ample number of diverse and complex PHP web development projects.</p>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(Laravel);
