import React from "react";
import emailjs from "emailjs-com";
import swal from "sweetalert";


const ContactOne = () => {

    // function onChange(value) {
    //     console.log("Captcha value:", value);
    // }

    function sendEmail(e) {
        e.preventDefault();

        console.log("form", e.target)


        emailjs
            .sendForm(
                "service_wd67r8v",
                "template_azf9e1h",
                e.target,
                "Jly865lom_TX8CG6u"
            )
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.log(err));
            swal("Sent successfully!","Your Form has been sent successfully, We will contact you soon!", "success")

    }

    return (
        <React.Fragment>
            <div className="contact-box ">
                <div className="contact-form--2">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="form-text">
                                <p >Looking for a project development</p>
                                <div className="subtitle">Please fill the form below to Contact Us</div>
                            </div>
                            <div className="col-lg-12 order-2 order-lg-1">

                                <div className="form-wrapper">

                                    <form
                                        name="contactform"
                                        onSubmit={sendEmail}
                                    >
                                        <label htmlFor="item01">
                                            <input
                                                type="text"
                                                name="firstname"
                                                placeholder="First Name"
                                                required
                                            />
                                        </label>
                                        <label htmlFor="item03">
                                            <input
                                                type="text"
                                                name="lastname"
                                                placeholder="Last Name"
                                                required
                                            />
                                        </label>
                                        <label htmlFor="item02">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Your Email"
                                                required
                                            />
                                        </label>


                                        <label htmlFor="item04">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="Phone Number"
                                                required
                                            />
                                        </label>
                                        <label htmlFor="item05">
                                            <select
                                                aria-label="Default select example"
                                                name="question"
                                                required
                                            >
                                                <option value="This question is about..">This question is about...</option>
                                                <option value="Web Development">Web Development</option>
                                                <option value="App Development">App Development</option>
                                                <option value="Digital Marketing">Digital Marketing</option>
                                                <option value="Business Strategies">Business Strategies</option>
                                                <option value="Other">Other</option>

                                            </select>
                                        </label>
                                        <label htmlFor="item06">
                                            <textarea

                                                name="message"

                                                placeholder="I have a question..."
                                            />
                                        </label>
                                        <div className="main-page-form-button">
                                            <button className="rn-button-style--2 btn-solid "

                                                type="submit"
                                                value="Send"
                                            >Submit
                                            </button>




                                            {/* <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>
                                            <ReCAPTCHA
                                                sitekey="6LcTewshAAAAADM5nCc62SWsxSLN2sr99ghUiQdk"
                                                   size="compact"
                                                onChange={onChange}
                                            />
                                               </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ContactOne);