import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const IphoneApp = () => {
    return (
        <React.Fragment>
            <PageHelmet 
               pageTitle="Best IPhone App Development Company in Lucknow"
               metaKeywords="Best IPhone App Development Company in Lucknow "
               metaDescription=" If you are searching for the IPhone App Development Company in Lucknow Ekana Technologies is a best Mobile App Development Company in Lucknow at low price "
               canonical="/Best-IPhone-App-Development-Company-in-Lucknow"
            
            />
{/* 
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={4} parent={"Services"}  title={'IPhone App Development'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>The iPhone has attracted users of all age groups and has gained immense popularity and status as it renders intricate functionalities to mobile phones and other handheld devices. Besides consumer use, the iPhone has also scaled to the business marketplace. We specialize in embedding unique functional intricacies in these gizmos by developing flexible, robust and innovative iPhone applications that run on Apple’s iOS mobile operating system. We possess a team of skilled and professional iPhone developers who have gained experience and proficiency in mobile application development using the latest development tools to provide industry-standard mobile solutions. Our developers are well-equipped and dedicated to developing flexible and adequately robust mobile applications catering to specific mobile platforms. We possess a team of skilled and professional iPhone developers who have acquired experience and proficiency in mobile application development using the latest development tools to provide industry-standard mobile solutions. Our services render cost-effective mobile solutions in a time-bound environment and guarantee high returns through your investment.</p>


                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}

            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(IphoneApp);
