import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";
import { Parallax } from 'react-parallax';
// import BrandTwo from "../../../elements/BrandTwo";
import DiscussProject from '../../../component/common/DiscussProject';

const WebDesign = () => {
    return (
        <React.Fragment>
            <PageHelmet     
            pageTitle="Best Web Design Company in Lucknow"
            metaKeywords="Best Web Design Company in Lucknow "
            metaDescription=" If you are searching for the Web Design Company in Lucknow Ekana Technologies is a best Web Design Company in Lucknow at low price "
            canonical="/Best-Web-Design-Company-in-Lucknow"

            />
            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={12} parent={"Services"} title={'Web Design'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content ptb--60 align-items-center">
                                            {/* <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="https://images.pexels.com/photos/845451/pexels-photo-845451.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Service Images" />
                                                </div>
                                            </div> */}
                                            <div className="col-lg-12 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>A picture speaks a thousand words - This saying was apt when there was no Internet. Today, a website speaks a thousand words. Your website is your brochure, the front face of your business. It informs your customers about your capabilities and offerings. So it has to be the best to leave your competition behind. Get the best web designing services by Ekana technologies.</p>
                                                    <p>In this fastest world of internet, people have a very low attention span. If you do not grab your audience within the first seconds’, your competitor grab them. Innovative & eye-catchy design is the first need of your business website.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    <Zoom bottom>
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>We, at Ekana Technologies, have taken web designing and execution to a new level. We use the latest technologies to develop flawless website designs to keep your audience stay on the web and turn them to become a customer. Our web design team is highly skilled in creating responsive web designs with fast execution time. Ekana Technologies offers the best UI & UX (user interface & User experience) services in Norway.</p>
                                                    <p>We start from scratch with the website design to seamlessly capture your vision about your organization and then build it from there to make that vision into a graphic representation through your site.</p>
                                                    <p>At Ekana technologies, we develop the fast loading & responsive website to increase your website traffic and search engine visibility.</p>
                                                    <h5 className='mt-3 mb-3'>What makes Ekana technologies special for web designing?</h5>

                                                    <ul className="liststyle">
                                                        <li>Responsive website designing and development</li>
                                                        <li>Customized and tailor-made solutions</li>
                                                        <li>Interactive design and sites that keep your visitors and returning</li>
                                                        <li>Unique, innovative and responsive design</li>
                                                        <li>User & Search engine friendly design that leaves your competition behind</li>
                                                        <li>Round-the-clock technical support</li>
                                                        <li>Timely deliverables</li>
                                                        <li>Cost-effective</li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Logo Design Services</h4>
                                                    <p>Our designing team analyzes your brand's potential users, their needs, market trends and then strategically designs a logo to empower your business with a distinct brand entity. We give your business a corporate, original, creative and specifically tailored look that expresses your values, personality, and competitive edge.</p>
                                                    <p> It's a matter of pride for us to have a highly skilled and competent team of graphic designers who can provide the best graphic design solutions. Our team makes you aware of how your competitors are leveraging the power of graphic design. While designing a logo we do the research regarding how your business can stand out by changing your strategies.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Banner Design Services</h4>
                                                    <p>It's a matter of pride for us to have a highly skilled and experienced team of designers who are known to create banners that instantly click with the target audience. We have dozens of happy clients across diverse industries.</p>
                                                    <p>Our unique and professional banners are created keeping in mind the client’s needs and are capable of catching worldwide attention for their targeted audience. Our high-quality service, long-lasting relationships, on-time delivery, and affordable prices are the reasons why we are the best graphics designing company.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Why Choose Us for Web Designing Services?</h4>
                                                    <p>Ekana Technologies is proficient in using frontend technologies HTML5, CSS3, Bootstrap4, JavaScript, Jquery, Angular. This enables us to make interactive websites that are unique but also result-oriented. Our technical team has the expertise and vast experience of the above-mentioned technologies.</p>


                                                    <ul className="liststyle">
                                                        <li>Understands the client's business requirements and objectives.</li>
                                                        <li>Competitor analysis and legal brand protection.</li>
                                                        <li>Render a customized corporate logo.</li>
                                                        <li>Compelling, communicative professional logo design.</li>
                                                        <li>Cost-effective and reliable solution.</li>
                                                        <li>100% customer satisfaction.</li>

                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            {/* End Page Wrapper */}



            {/* Start Brand Area */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                < DiscussProject />
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>
            {/* End Brand Area */}





            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(WebDesign);
