import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";



const MaintenanceEnhancement = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            pageTitle='Best Maintenance & Enhancement IT Company in Lucknow'
            metaKeywords=" best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, wordpress development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
            metaDescription=" Best Maintenance & Enhancement Company in Lucknow in Lucknow Ekana Technologies is the fastest-growing Digital Marketing Company in Lucknow. We are the Best App development company"
            canonical="/Best-Maintenance-Enhancement-IT-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={1} parent={"Services"}  title={' Maintenance & Enhancement'} />
            {/* End Breadcrump Area */}



            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--60 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                

                                                <p>Maintenance and Enhancement are designed to help organizations increase application efficiency, reduce system outage and drive high-performance. We serve all, from a standard application to the business-critical application on several platforms and technologies like databases, languages, and software including commercial-off-the-shelf applications.</p>
                                                <p>We provide a dedicated resource to manage high skilled application:-</p>

                                                <ul className="liststyle">
                                                    <li>Maintenance Management/Application Administration.</li>
                                                    <li>IT Application Support (User Support).</li>
                                                    <li>Corrective Maintenance.</li>
                                                    <li>Preventive Maintenance.</li>
                                                    <li>Adaptive Maintenance.</li>
                                                    <li>Knowledge Administration & Licenses (COTS).</li>
                                                    <li>Established matrix for help desk services.</li>
                                                    <li>Immediate issue resolution and root cause analysis.</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}




            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>




            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(MaintenanceEnhancement);
