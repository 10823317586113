import React from "react";
import { FaGithub, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const SocialShare = [
    { icon: 1, Social: <FaFacebookF />, link: 'https://www.facebook.com/ekanatechnologies/' },
    { icon: 2, Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/ekana-technologies' },
    { icon: 3, Social: <FaInstagram />, link: 'https://www.instagram.com/' },
    { icon: 4, Social: <FaGithub />, link: 'https://github.com/ekanatechnologies' },
]


const Footer = () => {

    // const [loading, setloading] = useState(true);


    return (
        <React.Fragment>
            <footer className="footer-area">
                <div className="footer-wrapper">
                    <div className="row align-items-end row--0">
                        <div className="col-lg-5">
                            <div className="footer-left">
                                <div className="inner">
                                    <span>READY TO LAUNCH YOUR IDEA!</span>

                             <h2>Let's get <br /> to work </h2> 

                                    
                                    
                                    <a className="rn-button-style--2" href="/contact">
                                        <span>Contact Us</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="footer-right" data-black-overlay="8">
                                <div className="row">
                                    {/* Start Single Widget  */}
                                    <div className="col-lg-4 col-sm-6 col-12 text-left">
                                        <div className="footer-link">
                                       
                                            <ul className="ft-link">
                                            <li><Link to="/Best-IT-company-In-Lucknow" >About Us</Link></li>
                                            <li><Link to="/Best-IT-Services-Company-in-Lucknow-PortFolio" >Portfolio</Link></li>
                                            <li><Link to="https://training.ekanatechnologies.com/" >Training</Link></li>
                                            <li><Link to="/privacy-policy" >Privacy Policy</Link></li>
                                            <li><Link to="/cookie-policy" >Cookie Policy</Link></li>
                                                


                                               


                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-12 text-left">
                                        <div className="footer-link">

                                            <ul className="ft-link ">
                                            <li><Link to="/" >Home</Link></li>
                                            <li><Link to="/Best-Software-Development-Company-in-Lucknow" >Services</Link></li>
                                            <li><Link to="/php-development" >Technologies</Link></li>
                                            <li><Link to="/Best-IT-company-In-Lucknow-contact" >Contact Us</Link></li>
                                            <li><Link to="/Best-IT-Services-Company-in-Lucknow-career" >Career</Link></li>

                                                {/* <li><a href="/">Home</a></li>
                                                <li><a href="/Best-Software-Development-Company-in-Lucknow">Services</a></li>
                                                <li><a href="/php-development">Technologies</a></li>
                                                <li><a href="/Best-IT-company-In-Lucknow-contact">Contact Us</a></li>
                                                <li><a href="/Best-IT-Services-Company-in-Lucknow-career">Career</a></li> */}

                                            </ul>
                                        </div>
                                    </div>

                                    {/* End Single Widget  */}
                                    {/* Start Single Widget  */}
                                    <div className="col-lg-4 col-sm-6 col-12 text-left">

                                        <div className="footer-link">

                                            <ul className="ft-link ">
                                                <li><a href="https://www.google.com/maps/dir/26.8977502,80.9981468/ekanatechnologies/@26.8936306,80.9915214,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x399959c6fc66a017:0xc4fe6d0216cd445d!2m2!1d80.9944315!2d26.8891518?entry=ttu">Address :</a></li>
                                                <li><a href="https://www.google.com/maps/dir/26.8977502,80.9981468/ekanatechnologies/@26.8936306,80.9915214,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x399959c6fc66a017:0xc4fe6d0216cd445d!2m2!1d80.9944315!2d26.8891518?entry=ttu">Ashirwad Plaza, 16/278/2, 2nd floor, Sector 16, Indira Nagar, Lucknow, Uttar Pradesh 206016</a></li>

                                            </ul>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="inner text-center ptb--30">
                                                <div className="footer-link ptb--30">

                                                    <ul className="ft-link ">
                                                        <li><a href="https://www.facebook.com/ekanatechnologies/">Follow Us</a></li>

                                                    </ul>
                                                </div>

                                                <ul className="social-share color-theme d-flex justify-content-center liststyle">
                                                    {SocialShare.map((val, i) => (
                                                        <li key={i} ><a className={`color-${val.icon}`} href={`${val.link}`}>{val.Social}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    {/* End Single Widget  */}

                                    <div className="copyright-text text-center">
                                        <p>Copyright © 2023 Ekana Technologies . All Rights Reserved.</p>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}

export default React.memo(Footer);