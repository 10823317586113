import React, { Component } from "react";
import { FaCaretRight } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import { Link } from "react-router-dom";


class TestHeader extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){

        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');

        console.log("elements",elements)

        for(var i in elements) {
            
            if(elements.hasOwnProperty(i)) {
       
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/ekanalogo.png`} alt="Ekana Technologies" />;
        } else if (logo === 'dark') {
            logoUrl = <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/ekanalogo.png`} alt="Ekana Technologies" />;
        } else if (logo === 'symbol-dark') {
            logoUrl = <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/ekanalogo.png`} alt="Ekana Technologies" />;
        } else if (logo === 'symbol-light') {
            logoUrl = <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/ekanalogo.png`} alt="Ekana Technologies" />;
        } else {
            logoUrl = <img width="100%" height="auto" loading="lazy" src={`assets/images/logo/ekanalogo.png`} alt="Ekana Technologies" />;
        }

        
        return(
            <header className={`header-area  header--fixed ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li ><Link to="/">Home</Link> </li>
                                <li className="has-droupdown"><a href="/Best-Software-Development-Company-in-Lucknow" className="open">Services</a>

                                    <ul className="submenu submenu-right active">
                                        <div className="container">
                                            <div className="row ">
                                                <div className="col-lg-3 col-sm-12 mt-10">

                                                    <li>
                                                        <Link to="/Best-Software-Development-Company-in-Lucknow"><FaCaretRight color="red" size={'1em'} />Software Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Client-Server-Applications-Handle-Company-in-Lucknow">Client Server Applications</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Database-Applications-Development-Company-in-Lucknow">Database Applications</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Software-Component-Development-Company-in-Lucknow">Component Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Maintenance-Enhancement-IT-Company-in-Lucknow">Maintenance</Link>
                                                    </li>


                                                </div>

                                                <div className="col-lg-3 col-sm-12">

                                                    <li>
                                                        <Link to="/Best-Web-Development-Company-In-Lucknow"><FaCaretRight color="red" size={'1em'} />Web Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Custom-Web-Development-Company-in-Lucknow">Custom Web Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-eCommerce-Development-Company-in-Lucknow">eCommerce Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-MLM-Development-Company-in-Lucknow">
                                                            MLM Development
                                                        </Link>
                                                    </li>


                                                </div>
                                                <div className="col-lg-3 col-sm-12">

                                                    <li>
                                                        <Link to="/Best-Mobile-App-Development-Company-in-Lucknow"><FaCaretRight color="red" size={'1em'} />Mobile App Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Android-App-Development-Company-in-Lucknow">Android App Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Hybrid-App-Development-Company-in-Lucknow">Hybrid App Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-IPhone-App-Development-Company-in-Lucknow">iPhone App Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Flutter-App-Development-Company-in-Lucknow">Flutter App Development</Link>
                                                    </li>


                                                </div>

                                                <div className="col-lg-3 col-sm-12">

                                                    <li>
                                                        <Link to="/Best-WordPress-Development-Company-in-Lucknow"><FaCaretRight color="red" size={'1em'} />WordPress Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-WordPress-Theme-Development-Company-in-Lucknow">WP Theme Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Wordpress-Customization-Company-in-Lucknow">WordPress Customization</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-WordPress-Plugin-Development-Company-in-Lucknow">
                                                            WP Plugin Development
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-WordPress-Security-Fixes-Company-in-Lucknow">
                                                            WordPress Security Fixes
                                                        </Link>
                                                    </li>


                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col-lg-3 col-sm-12">

                                                    <li>
                                                        <Link to="/Best-Design-Service-Company-in-Lucknow"><FaCaretRight color="red" size={'1em'} />Design Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Web-Design-Company-in-Lucknow">Web Design</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Logo-Design-Company-in-Lucknow">Logo Design</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-Banner-Design-Company-in-Lucknow">
                                                            Banner Design
                                                        </Link>
                                                    </li>


                                                </div>
                                                <div className="col-lg-3 col-sm-12">

                                                    <li>
                                                        <Link to="/Best-Product-Development-Company-in-Lucknow"><FaCaretRight color="red" size={'1em'} />Product Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/Best-ERP-Development-Company-in-Lucknow">ERP Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/The-Best-CRM-Development-Company-in-Lucknow">CRM Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/The-Best-Inventory-System-Development-Company-in-Lucknow">Inventory System Development</Link>
                                                    </li>

                                                </div>

                                                <div className="col-lg-3 col-sm-12">

                                                    <li>
                                                        <Link to="/Best-Digital-Marketing-Company-in-Lucknow"><FaCaretRight color="red" size={'1em'} />Digital Marketing</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/best-Search-Engine-Optimization-company-in-lucknow">Search Engine Optimization</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/best-Social-Media-Optimization-company-in-lucknow">Social Media Optimization</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/best-Pay-Per-Click-Services-company-in-lucknow">
                                                            Pay Per Click (PPC) Services
                                                        </Link>
                                                    </li>


                                                </div>
                                                {/* <div className="col-lg-3 col-sm-12">
                                                <img style={{width:"100%" ,height:"200px", marginTop:"-50px"}}  src="https://ekanatechnologies.com/wp-content/uploads/2023/04/softwere_dev-removebg-preview-1.png" alt="Software Development" />
                                                </div> */}

                                            </div>
                                        </div>
                                    </ul>
                                </li>



                                <li className="has-droupdown"><a href="/lavavel-development"  className="" >Technologies</a>

                                    <ul className="submenu submenu-left " >

                                        <div className="container">
                                            <div className="row ">

                                                <div className="col-lg-3 col-md-12">

                                                    <li>
                                                        <Link to="#"><FaCaretRight color="red" size={'1em'} />Front-end Technologies</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/angular">Angular</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/react-js">React</Link>
                                                    </li>


                                                </div>

                                                <div className="col-lg-3 col-md-12">

                                                    <li>
                                                        <Link to="#"><FaCaretRight color="red" size={'1em'} />Back-end Technologies</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/php-development">PHP Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/lavavel-development">Laravel Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/codeignite-development">
                                                            CodeIgniter Development
                                                        </Link>
                                                    </li>


                                                </div>

                                                <div className="col-lg-3 col-md-12">

                                                    <li>
                                                        <Link to="#"> <FaCaretRight color="red" size={'1em'} />Mobile App Technologies</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/android">Android</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/ios">iOS</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/react-native">
                                                            React Native
                                                        </Link>
                                                    </li>


                                                </div>
                                                <div className="col-lg-3 col-md-12">

                                                    <li>
                                                        <Link to="#"> <FaCaretRight color="red" size={'1em'} />CMS Technologies</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/wordpress-developments">WordPress Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/magento-development">Magento Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/joomla-development">Joomla Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/drupal-development">Drupal Development</Link>
                                                    </li>


                                                </div>
                                            </div>
                                        </div>


                                    </ul>
                                </li>
                                <li><Link to="/Best-IT-company-In-Lucknow" > About Us</Link></li>

                                <li><Link to="/Best-IT-Services-Company-in-Lucknow-PortFolio">Portfolio</Link></li>


                                <li><Link to="https://training.ekanatechnologies.com/">Industrial Training</Link></li>
                                <li><Link to="/Best-IT-company-In-Lucknow-contact" >Contact Us</Link></li>


                            </ul>
                        </nav>

                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default React.memo(TestHeader);