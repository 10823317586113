import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";
import { Parallax } from 'react-parallax';
// import BrandTwo from "../../../elements/BrandTwo";
import DiscussProject from '../../../component/common/DiscussProject';

function DigitalMarketing() {
    return (
        <React.Fragment>
            <PageHelmet
                pageTitle='Best Digital Marketing Company in Lucknow'

                metaKeywords="best digital marketing company Lucknow, seo company in lucknow, best digital marketing agency in Lucknow, website creators "

                metaDescription="If you are searching for the Best Digital Marketing Company in Lucknow Ekana Technologies is a Top Digital Marketing and Web Development Company in Lucknow at a low price"
                canonical="/Best-Digital-Marketing-Company-in-Lucknow"


            />
            {/* 
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={8} parent={"Services"} title={'Digital Marketing'} />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    <Zoom bottom>
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h1 className='title'>Choose Your Digital Marketing with The Best Digital Marketing Company</h1>
                                                    <p>The door-to-door approach of marketing is now a thing of old days. After the door-to-door approach print media, paper advertisement gained popularity. But as the technology grew by leaps and bounds, and today, the internet is the primary source and setting for market tactics. We offer Best Digital Marketing services to our clients as it has gained top priority.</p>

                                                </div>
                                            </div>

                                        </div>
                                        {/* End Single Area */}
                                    </Zoom>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="rn-service-details bg_color--1">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">



                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">
                                  
                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h2 className='title'>Best Search Engine Optimization</h2>
                                                    <p>To maintain your company relevancy we assist you with the best pay per click management strategy along with the PPC marketing plan. This will help in boosting your brand awareness and increasing the revenue flow.</p>
                                                    <p>The hierarchy which we follow in the development of a customized promotion for you is the investigation of your paid performances, then we evaluate the drawback of the execution and finally, we consider the plan for future improvement.</p>
                                                    <p className='liststyletitle'>While setting out your Pay Per Click account and paid media campaign, we always ensure that the even the minutest detail is taken into account which comprises:-</p>
                                                    <ul className="liststyle">
                                                        <li>PPC Research and Planning</li>
                                                        <li>PPC Keyword Analysis</li>
                                                        <li>PPC Strategy</li>
                                                        <li>PPC Deployment and Testing</li>
                                                        <li>PPC Management</li>

                                                    </ul>

                                                    <p className='mt-3'>Our excellent group of professionals who have excelled in PPC specialization will assist you. Our involvement in managing the PPC campaign will take off all your anxiety. Working together we promise to meet the target by ensuring that you get value worth of every penny you spent on your paid media strategy.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rn-service-details ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center ">
                                      
                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h2 className='title'>Best Pay Per Click (PPC) Services</h2>
                                                    <p>To maintain your company relevancy we assist you with the best pay per click management strategy along with the PPC marketing plan. This will help in boosting your brand awareness and increasing the revenue flow.</p>
                                                    <p>The hierarchy which we follow in the development of a customized promotion for you is the investigation of your paid performances, then we evaluate the drawback of the execution and finally, we consider the plan for future improvement.</p>
                                                    <p className='liststyletitle'>While setting out your Pay Per Click account and paid media campaign, we always ensure that the even the minutest detail is taken into account which comprises:-</p>
                                                    <ul className="liststyle">
                                                        <li>PPC Research and Planning</li>
                                                        <li>PPC Keyword Analysis</li>
                                                        <li>PPC Strategy</li>
                                                        <li>PPC Deployment and Testing</li>
                                                        <li>PPC Management</li>

                                                    </ul>

                                                    <p className='mt-3'>Our excellent group of professionals who have excelled in PPC specialization will assist you. Our involvement in managing the PPC campaign will take off all your anxiety. Working together we promise to meet the target by ensuring that you get value worth of every penny you spent on your paid media strategy.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}

            <div className="rn-service-details  mt-2 mb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">

                  
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <h2 className='title'>Best Social Media Optimization</h2>
                                                    <p>Social Media is a significant tool to optimize any service by increasing the number of visitors on the website. Social Media Optimized website impacts a larger group of the visitors available across the internet than any other advertising tools that are available nowadays. A significant number of users use social networks where chats and sharing plays an important role. </p>
                                                    <p>Thus, a company seeking to connect with its users directly can use blogs, forums, communities and popular networks as all of this work spectacularly for this purpose. By being a part of this hot mass obsession you have the potential to reach out with your services among the thousands of users across the web.</p>
                                                    <p className='mb-3'>Ekana technologies is a well-known SMO company as we are equipped with an expert team of professionals who are thoroughly experienced and skilled in providing custom, innovative, successfully implemented strategies and dedicated social media optimization services which include-</p>
                                                    <ul className="liststyle">
                                                        <li>Helps in establishing brand awareness in social networks</li>
                                                        <li>Driving heavy traffic from social networking sites to your website</li>
                                                        <li>Fusing internet marketing campaigns and SEO for the site</li>
                                                        <li>Helping with improving link popularity and gaining non-reciprocal links</li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Start Brand Area */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                < DiscussProject />
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>
            {/* End Brand Area */}





            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(DigitalMarketing);
