import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
// import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Wordpress = () => {
  return (
    <React.Fragment>
            <PageHelmet 
            
            pageTitle='Best WordPress Development Company in Lucknow | WordPress Development' 
            
            metaKeywords=" wordpress development company in lucknow, best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
  
            metaDescription=" If you are searching for the Best WordPress Development Company in Lucknow Ekana Technologies is a Top Digital Marketing and Software Development Company in Lucknow."
            canonical="/wordpress-developments"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            {/* <Breadcrumb No={5} parent={"Services"}  title={'WordPress Development'} /> */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-12 col-12">
                                            <div className="thumb">
                                                <img loading="lazy" style={{ width:"200px",maxHeight:"400px",marginLeft:"42%"}} src="https://ekanatechnologies.com/wp-content/uploads/2020/02/WordPress-development.jpg" alt="Service Images" />
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>By utilizing tested development methodologies and avant-garde technologies, Ekana Technologies develop WordPress development solutions that minimize web development complexities. The developers of our software outsourcing firm are proficient at all aspects of using WordPress to render websites that stay aligned with client’s requirements.</p>
                                              <p>WordPress use Plugins to extend & modify functionality. We at Ekana Technologies work closely with you to plan, build up and integrate the plugin as it is envisaged by you.</p>
                                               <p>Our professionals understand the necessity of SEO-friendly web coding and thus, ensure to code every website to improve the search-ability of your website on the search engines. STPL develop feature-rich, interactive websites for small to big enterprises or organizations.</p>
                                               
                                                <h5 className='mt-3 mb-3'>WordPress Development Services</h5>
                                                <p><strong>CMS Development</strong> CMS DevelopmentSTPL develop compelling websites that work best on WordPress CMS.</p>
                                               
                                                <p> <strong>WordPress Plugins Installation</strong>Plugins are the readymade code for WordPress. Moreover being a software outsourcing firm, Ekana Technologies provides custom-made plugins to improve the functionality of your WordPress site.</p>
                                               
                                                <p> <strong>WordPress Theming</strong>Our WordPress development services help transform from one theme to another without altering the existing PHP or HTML code.</p>
                                                <p><strong>WordPress Maintenance and Support</strong>We update your website regularly and offer full-control for any type of error, user-friendly testing, SEO, loading time checks and any other enhancement you require.Our client's website security is of utmost importance to us.</p>
                                                <p> <strong>WordPress Data Management</strong>For enterprises with a large database, we offer automated database management or manual data entry or database optimization.</p>
                                                <p><strong>eCommerce on WordPress</strong>  Interactive and well managed eCommerce websites that help enhance the sales and thus the ROI.</p>
                                                <p><strong>PSD to WordPress</strong>Our  professional designers and developers are proficient in hand coding and changing PSDs and PNGs into the most visited, reliable and full service WordPress website.</p>
                                                <p><strong></strong> WordPress SEO Our SEO experts optimize your website bringing it ahead of competitors and on the top of search engine rankings.</p>
                                             
                                               

                                                <h5 className='mt-3 mb-3'>Why Ekana Technologies?</h5>

                                                <ul className="liststyle">
                                                    <li>End-to-end solution for WordPress development.</li>
                                                    <li>24 X 7 development support across all time zones.</li>
                                                    <li>Strong technology proficiency.</li>
                                                    <li>Regular communication through Phone/Skype/Chat/Email.</li>
                                                    
                                                </ul>


                                                <p className='mt-3'>We believe that your ideas and business objectives are the foundation from where we can begin with building a remarkable CakePHP shopping cart solution for you.</p>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
  )
}

export default React.memo(Wordpress);
