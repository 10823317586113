import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
// import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Android = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            
            pageTitle='Best Android Development Company in Lucknow | Android'         
            metaKeywords="Best Android Development in lucknow" 
            metaDescription=" If you are searching for the Best Android Development Company in Lucknow Ekana Technologies is a Top Android Development and Software Development Company in Lucknow." 
            canonical="/android"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            {/* <Breadcrumb No={5} parent={"Services"}  title={'Android'} /> */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-12 col-12">
                                            <div className="thumb">
                                                <img width="100%" height="auto" loading="lazy" style={{ width: "200px", maxHeight: "400px", marginLeft: "42%" }} src="https://ekanatechnologies.com/wp-content/uploads/2020/02/android.jpg" alt="Service Images" />
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>There has been a great evolution in the mobile operating system during the last 15 years from Palm OS to Windows pocket PC, Blackberry OS, and Android. Android is one of the most popular </p>
                                                <p>Android is a Linux based mobile operating system that allows advanced computer processing. Android technology is owned by Google, Inc., which comprises an operating system, key applications, and software. The technology is unique, as the platform is actively developed by Google, but given to hardware manufacturers and phone carriers for free.</p>
                                                <h5 className='mt-3 mb-3'>Highlights of Android Technology</h5>
                                                <p>CakePHP is actually PHP/MYSQL framework developed by utilizing the concept of Ruby on Rails. It helps develop applications with superior functionality and features. The framework supports Model View Controller and Object Relationship Mapping Architecture which proffer environment for both the designer and developers to work at altogether.</p>
                                                <p>CakePHP enables an easier and quicker way of creating web applications, as it entails less code.</p>
                                                <h5 className='mt-3 mb-3'>Key Benefits </h5>

                                                <ul className="liststyle">
                                                    <li>It supports numerous applications in Smart Phones, which are highly user-friendly and customized for the users.</li>
                                                    <li>Android has an online software market, which is also known as the Android market that contains over 2.0 lakh games, applications, and widgets. Android OS, allows Android users to select and download applications created by 3rd party developers and utilize them.</li>
                                                    <li>Android applications are developed by using Java programming language.</li>
                                                    <li>For core system services such as memory management, network stack, driver model, process management, security, Android completely relies on Linux version 2.6.</li>
                                                    <li>It comprises of the software development kit (Android SDK).</li>

                                                </ul>

                                                <h5 className='mt-3 mb-3'>Android Applications</h5>

                                                <ul className="liststyle">
                                                    <li>Android applications consist of two or more applications components, i.e. activities, content providers, services and broadcast receivers.</li>
                                                    <li>Each component plays a distinct role in the overall application behavior and can be activated individually.</li>
                                                    <li>All components of the application and its requirements must be declared by a manifest file.</li>
                                                    <li>Non-code application resources must comprise alternatives for various device configurations, i.e. different strings for various languages.</li>


                                                </ul>

                                                <h5 className='mt-3 mb-3'>Security and Privacy </h5>

                                                <ul className="liststyle">
                                                    <li>All Android applications run in a secluded area of the system, i.e. sandbox, that doesn’t have admittance to the rest of the system’s resources unless the permission is granted by the user while installing the application.</li>
                                                    <li>The sandbox and permission system minimizes the impact of bugs and vulnerabilities in applications.</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(Android);
