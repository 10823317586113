import React from "react";
import PageHelmet from "../component/common/Helmet";
import { FiPhone, FiMapPin } from "react-icons/fi";
import Zoom from 'react-reveal/Zoom';
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../component/header/Header";
// import Footer from "../component/footer/Footer";
// import { useLoadScript } from "@react-google-maps/api";
// import Map from "./Map";
import Breadcrumb from "./common/Breadcrumb";
import { Parallax } from "react-parallax";
// import GooglemapComponent from "./GooglemapComponent";
import Iframe from "react-iframe";


// const AnyReactComponent = ({ text }) => <h6 style={{color:'red'}}>{text}</h6>;


const Contact = () => {


    return (
        <React.Fragment>
            <PageHelmet
                pageTitle='Best IT Company in Lucknow'
                metaKeywords="best digital marketing company Lucknow, seo company in lucknow, best digital marketing agency in Lucknow, website creators "
                metaDescription="If you are searching for the Best IT Company in Lucknow Ekana Technologies  is a Top E-commerce Development and Software Development  Company in Lucknow at low price"
                canonical="/Best-IT-company-In-Lucknow-contact"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}

            {/* Start Breadcrump Area */}
            <Breadcrumb No={5} title={'Contact'} />

            {/* End Breadcrump Area */}


            <div className="rn-contact-page ptb--60 bg_color--1">
                <h1 className="text-center ">Contact with The Best IT Service Company in Lucknow</h1>

            </div>

         
            {/* Start Contact Top Area  */}
            <div className="rn-contact-top-area pt--40 bg_color--1">
                <div className="container">
                    <Zoom bottom>
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <span className="icon-1">    <FiPhone color="#e63024" style={{ fontWeight: 800, fontSize: "25px" }} /></span>


                                    <div className="inner">
                                        <p className="title">Contact With Phone Number</p>
                                        <p><a href="tel:+057 254 365 456">+91-9839642424</a></p>
                                        <p><a href="tel:+856 325 652 984">+91-522 - 4046093</a></p>
                                        <p><a href="mailto:admin@gmail.com">info@ekanatechnologies.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">

                                    <span className="icon-1"> <FiMapPin color="#e63024" style={{ fontWeight: 800, fontSize: "25px" }} /></span>



                                    <div className="inner">
                                        <p className="title">Lucknow</p>

                                        <p>Ashirwad Plaza, 16/278/2, 2nd floor, Sector 16, Indira Nagar, Lucknow, Uttar Pradesh 206016</p>

                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <span className="icon-1"><FiMapPin color="#e63024" style={{ fontWeight: 800, fontSize: "25px" }} /></span>

                                    <div className="inner">
                                        <p className="title">USA Office Address</p>
                                        <p>6917, Ball Camp Pike, <br /> Knoxville TN 37931</p>
                                        <p>,</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </Zoom>
                </div>
            </div>
            {/* End Contact Top Area  */}

            {/* Start Contact Page Area  */}

            <div className="rn-contact-page ptb--120 bg_color--1">

                <Zoom bottom>
                    <ContactTwo />
                </Zoom>
            </div>
            {/* End Contact Page Area  */}

            <div className="Map">
                <Iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14233.80508352389!2d80.97597738044675!3d26.889169739504666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399959c6fc66a017%3A0xc4fe6d0216cd445d!2sEkana%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687506831209!5m2!1sen!2sin "
                    frameBorder="0"
                    height="450"
                    width="100%"
                    loading="lazy"
                />
            </div>


            {/* Start Contact Map  */}
            {/* <div className="rn-contact-map-area position-relative">
                <div style={{  width: '100%',height: '400px', }}>
                {
                        isLoaded ? <GooglemapComponent/> : null
                    }
                    
                </div>
            </div> */}
            {/* End Contact Map  */}


            {/* Start Brand Area */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>
            {/* End Brand Area */}




            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}



export default React.memo(Contact);