import React from "react";
import { Link } from "react-router-dom";
// import Zoom from 'react-reveal/Zoom';


const Breadcrumb = (props) => {
    const { title, parent, No } = props;
    return (
        <React.Fragment>
           <div className={`breadcrumb-area rn-bg-color pt--221 bg_image bg_image--${No}`}>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner-1  " style={{marginTop: "21%"}}>                
                                <ul className="page-list">
                                    <li className="breadcrumb-item"><Link to={`/`}>Home</Link></li>
                                    {parent ? <li className="breadcrumb-item">{parent}</li> : ''}
                                    <li className="breadcrumb-item active">{title}</li>
                                </ul>
                            </div>

                        </div>
                      
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Breadcrumb);

