import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import DiscussProject from '../../../component/common/DiscussProject';
import { Parallax } from 'react-parallax';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const AndroidApp = () => {
    return (
        <React.Fragment>
            <PageHelmet 
                  pageTitle="Best Android App Development Company in Lucknow"
                  metaKeywords="Best Android App Development Company in Lucknow "
                  metaDescription=" If you are searching for the Android App Development Company in Lucknow Ekana Technologies is a best Android App Development Company in Lucknow at low price "
                  canonical="/Best-Android-App-Development-Company-in-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={4} parent={"Services"}  title={' Android App Development'} />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                  

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                             
                                                <p>Android is the most popular and most widely used mobile operating system. With the frequency of increasing android devices in the marketplace, not a single business wants to miss the chance to take the benefit of android applications. Even we can say that the android application is mandatory for all types of businesses.</p>
                                                <p>So that if you are looking for an android development company for your business then you are at the right place. Ekana technologies is the best mobile app development company in Lucknow. We are experts in developing innovative & eye-catchy android applications. We have a team of expert android developers who have a vast knowledge of android app development. We have certified professionals and developers who are well-versed with the latest technologies and proven methodologies.</p>
                                                <p>Ekana technologies customize the android application based on the client's business requirements. So we always look forward to creating a fast and user-friendly application that helps you to grow your business as per your expectations.</p>
                                                <p>We follow the latest and proven methodology to develop an android application. We focus on:-</p>
                                                <p>Analyze the client's business objectives and requirements</p>
                                                <p>Customize the mobile app as per business requirements and generate the application flow.</p>
                                                <p>Build a fast and user-friendly android application with the latest technologies.</p>
                                                <p>Our android developers have years of experience and developed numerous Android applications for different kinds of business globally. Our developers are experts in developing an eCommerce application, CRM application, ERP application, MLM application, Etc.</p>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}


                                  


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">Why Ekana Technologies?</h4>

                                                <ul className="liststyle">
                                                    <li>Develop fast and customized Android applications with the latest technologies.</li>
                                                    <li>24X7 Technical support and maintenance.</li>
                                                    <li>Experienced professionals who have knowledge about industry trends and technologies.</li>
                                                    <li>Develop a secure and bug-free android application.</li>



                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}

            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>







            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(AndroidApp);
