import React from 'react'
// import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
// import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const Codeigniter = () => {
  return (
    <React.Fragment>
    <PageHelmet 
      pageTitle='Best CodeIgniter Development Company in Lucknow | Ekana Technologies' 
            
      metaKeywords="Best CodeIgniter Development in lucknow"
    
      metaDescription=" If you are searching for the Best CodeIgniter Development Company in Lucknow Ekana Technologies is a Top CodeIgniter Development and Software Development Company in Lucknow."
    
      canonical="/codeignite-development"
    
    
    />

    {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
    {/* Start Breadcrump Area */}
    {/* <Breadcrumb No={5} parent={"Services"}  title={'CodeIgniter Development'} /> */}
    {/* End Breadcrump Area */}

    {/* Start Page Wrapper */}
    <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="service-details-inner">
                        <div className="inner">
                            {/* Start Single Area */}
                            {/* <Zoom bottom>
                            <div className="row sercice-details-content pb--80 align-items-center">
                                <div className="col-lg-12 col-12">
                                    <div className="thumb">
                                        <img  loading="lazy" style={{ width:"200px",maxHeight:"400px",marginLeft:"42%"}} src="https://ekanatechnologies.com/wp-content/uploads/2020/02/code-ignitor.jpg" alt="Service Images" />
                                    </div>
                                </div>

                            </div>
                            </Zoom> */}
                            {/* End Single Area */}

                            {/* Start Single Area */}
                            {/* <div className="row sercice-details-content align-items-center">

                                <div className="col-lg-12 col-12 order-2 order-lg-1">
                                    <div className="details mt_md--30 mt_sm--30">
                                        <p>Ekana Tachnologies has provided different businesses with a most acquiescent platform by rendering stable and scalable CakePHP web applications. We aim to render the custom CakePHP solution at reduced costs. Being an established software solution outsourcing company, Ekana Technologies offers services including CakePHP Module Development, CakePHP Upgrade & Extension Development, customized applications etc.</p>
                                        <p>Our team of developers even provides support to the existing CakePHP web applications without hindering their real functionality. We endeavor to make maximum benefit from industry standard plugins & libraries to ensure that the application is extensible and scalable.</p>

                                        <h5 className='mt-3 mb-3'>CakePHP Framework</h5>
                                        <p>CakePHP is actually PHP/MYSQL framework developed by utilizing the concept of Ruby on Rails. It helps develop applications with superior functionality and features. The framework supports Model View Controller and Object Relationship Mapping Architecture which proffer environment for both the designer and developers to work at altogether.</p>
                                        <p>CakePHP enables an easier and quicker way of creating web applications, as it entails less code.</p>
                                        <h5 className='mt-3 mb-3'>Key Benefits </h5>

                                        <ul className="liststyle">
                                            <li>Free open source PHP framework.</li>
                                            <li>With Unit Testing facility.</li>
                                            <li>Compatible with PHP5 and PHP4.</li>
                                            <li>View caching and data sanitization.</li>
                                            <li>Shared hosting.</li>
                                            <li>Fast application development.</li>
                                            <li>Access control list.</li>
                                            <li>Easy designing and development.</li>
                                            <li>No configuration needed.</li>
                                            <li>Code generation using Bake.</li>
                                            <h5 className='mt-3 mb-3'>Why Ekana technologies?</h5>
                                            <li>Developers with deep domain knowledge are involved in component development.</li>
                                            <li>Rapid web application deployment and development services at affordable price.</li>
                                            <li>Work on XHTML, CSS, AJAX, CakePHP, HTML5 and Javascript.</li>
                                            <li>High end service delivery with (24 X 7) technical support.</li>
                                            <li>Strict time management and reliable custom solutions.</li>
                                            <li>Successfully delivered all CakePHP projects within budget.</li>
                                        </ul>


                                        <p className='mt-3'>We believe that your ideas and business objectives are the foundation from where we can begin with building a remarkable CakePHP shopping cart solution for you.</p>
                                    </div>
                                </div>

                            </div> */}
                            {/* End Single Area */}





                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* End Page Wrapper */}

    <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>

    {/* Start Back To Top */}
    <div className="backto-top">
        <ScrollToTop showUnder={160}>
            <FiChevronUp />
        </ScrollToTop>
    </div>
    {/* End Back To Top */}

    {/* <Footer /> */}

</React.Fragment>
  )
}

export default React.memo(Codeigniter);
