import React from 'react';
import './Flywheel.css'

const Flywheel = () => {
    return (
        <React.Fragment>

            <div className="grow">
                <div data-v-288e7948="" className="growth">
                    {/* <div data-v-288e7948="" class="label"><span data-v-288e7948=""> Growth</span> Flywheel </div> */}
                    <div data-v-288e7948="" className="img-box">
                        <div data-v-288e7948="" className="inner-box">
                            <img data-v-288e7948="" width="100%" height="auto" loading="lazy" src="assets/img/flywheel-logo.png" alt="We turn your vision into a success story"   className="img1" />
                            <img data-v-288e7948="" width="100%" height="auto" loading="lazy"  src="assets/img/untitled.png"  alt="We turn your vision inside digital world" className="img2" />
                            <div data-v-288e7948="" className="dot-list">
                                <div data-v-288e7948="" className="item1"></div>
                                <div data-v-288e7948="" className="item2 ac"></div>
                                <div data-v-288e7948="" className="item3"></div>
                                <div data-v-288e7948="" className="item4"></div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

export default Flywheel ;
