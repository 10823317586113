import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const SecurityFixes = () => {
  return (
    <React.Fragment>
            <PageHelmet 
               pageTitle='Best WordPress Security Fixes Company in Lucknow' 
            
               metaKeywords=" wordpress development company in lucknow, best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
     
               metaDescription=" If you are searching for Best WordPress Security Fixes Company in Lucknow Ekana Technologies is a Top Digital Marketing and Software Development Company in Lucknow."
               canonical="/Best-WordPress-Security-Fixes-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={3} parent={"Services"}   title={'WordPress Security Fixes'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                  {/* Start Single Area */}
                                  <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                              
                                                <p>If a WordPress website is important to your business, then your most important need is most likely going to be the peace of mind. While you concentrate on your business, you would want the website to run smoothly. If your website is based on the subscription, it’s even more critical to have the uptime monitored, and the site backed up regularly. Maintaining a website can be a time-sink task if the necessary skills, available bandwidth, and resources are not available to you.</p>
                                                <p>To run a WordPress website run successfully, a lot of work has to be done both on the front-end and the back-end. Regular update is the key to keep the site ticking over, running at peak performance and most importantly, remaining secure from hackers and malicious scripts. WordPress website has been an easy target for security exploits, not because it’s vulnerable but out of its sheer popularity. Because of its popularity, it has become a very important aspect to maintain and update the WordPress website.</p>
                                                <p>As a WordPress security provider, we take care of the constant WordPress core, plugin and theme updates, backups, optimization and development.</p>
                                                <h5 className='mt-3'>We perform the following Security Checks and Updation Activities:-</h5>
                                                <ul className="liststyle">
                                                    <li>Upgrading WordPress Core, Plugins and Themes</li>
                                                    <li>Server and Uptime Monitoring</li>
                                                    <li>Versioning of code and Backups</li>
                                                    <li>Content Updates and SEO Analysis</li>
                                                    <li>Conversion Optimization, Performance Optimization, and Evaluation</li>
                                                    <li>WordPress bug fixes</li>

                                                </ul>

                                                

                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}


            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
  )
}

export default React.memo(SecurityFixes);
