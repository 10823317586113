import React from 'react'
import styled from 'styled-components';
import SliderComp from './Slider';
import './Project.css'


const Testimonial = () => {
  return (
    <Container id='project'>
        <Slide>
            <SliderComp/>
        </Slide>
    </Container>
  )
}

export default Testimonial;

const Container = styled.div`
    width:100%;
    max-height:350px;
    margin: auto;
    padding: 0;
    text-align: center;
    position: relative;
    @media(max-width: 840px){
        width:100%;
    }
    h1{
        font-size: 1.9rem;
    }

    p{
        width: 28rem;
        margin: 0 auto;
        padding: 1rem 0;
        font-size: 0.9rem;
        @media(max-width : 500px){
            width:100%;
        }
    }
    
`

const Slide = styled.div``