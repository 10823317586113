import React from 'react'


const DiscussProject = () => {
  return (
    <div className='discuss-main-box'>
        <div className='discuss-container-1'>
           <h3>We'd love to discuss your next project.</h3>
            <div className="rn-form-group mb--30">
                <a className="rn-button-style--2 btn-solid " href='/Best-IT-company-In-Lucknow-contact'>Get Started Now</a>
            </div> 
        </div>
      
    </div>
  )
}

export default DiscussProject;

