import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
// import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";

const Ios = () => {
  return (
    <React.Fragment>
            <PageHelmet 
            
            pageTitle='Best iOS Development Company in Lucknow | iOS'         
            metaKeywords="Best iOS Development in lucknow" 
            metaDescription=" If you are searching for the Best iOS Development Company in Lucknow Ekana Technologies is a Top iOS Development and Software Development Company in Lucknow." 
            canonical="/ios"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            {/* <Breadcrumb No={1} parent={"Services"}  title={'iOS'} /> */}
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    {/* <Zoom bottom>
                                    <div className="row sercice-details-content pb--80 align-items-center">
                                        <div className="col-lg-12 col-12">
                                            <div className="thumb">
                                                <img  style={{ width:"200px",maxHeight:"400px",marginLeft:"42%"}} src="https://ekanatechnologies.com/wp-content/uploads/2020/02/ios-development.jpg" alt="Service Images" />
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>iPad, iPhone, and iPod touch devices running on the iOS operating system. The operating system manages the device hardware and renders the technologies required to create native apps. The iOS operating system ships with various system apps, i.e. Safari, Phone, and Mail which offer standard system services to the user.</p>
                                              <p>One can find the tools and interfaces which are needed to install, run and test native apps that become visible on an iOS device’s home screen. Native apps are developed by making use of iOS system frameworks and Objective-C language. Then it is made to run directly on iOS.</p>
                                                <p>Native apps always remain available to the user as they are installed physically on a device, unlike web apps.</p>
                                                <h5 className='mt-3 mb-3'>CakePHP Framework</h5>
                                                <p>CakePHP is actually PHP/MYSQL framework developed by utilizing the concept of Ruby on Rails. It helps develop applications with superior functionality and features. The framework supports Model View Controller and Object Relationship Mapping Architecture which proffer environment for both the designer and developers to work at altogether.</p>
                                                <p>CakePHP enables an easier and quicker way of creating web applications, as it entails less code.</p>
                                                <h5 className='mt-3 mb-3'>Layered iOS Architecture</h5>
                                                <p>iOS acts as a mediator between the apps you create and the underlying hardware. Apps converse with the hardware through a set of distinct system interfaces. These interfaces enable us to write apps easily which work consistently on devices having distinct hardware capabilities Higher-level layers are available on the top that is built upon the lower layers in the iOS technologies layered architecture.</p>
                                                <h5 className='mt-3 mb-3'> The layers of iOS are:</h5> 
                                                <ul className="liststyle">
                                                    <li><strong >Cocoa touch</strong>- It consists of key frameworks for creating iOS apps. This framework identifies the appearance of apps together with basic infrastructure and support for technologies, i.e. push notifications, multitasking, touch-based input, and high-level system services.</li>
                                                    <li><strong >Media</strong>- This layer comprises of audio, graphics and video technologies.</li>
                                                    <li><strong >Core services</strong>-It consists of fundamental system services for apps, i.e., the Core Foundation and Foundation Frameworks.</li>
                                                    <li><strong >Core OS</strong>- It comprises of low-level features that are required to build most of the other technologies.</li>

                                                    
                                                </ul>
                                             <p className='mt-3 mb-3'>  The higher-level frameworks mainly provide object-oriented abstractions for lower-level constructs.</p>
                                            
                                             <h5 className='mt-3 mb-3'>Frameworks</h5> 
                                                <p>Most of the system interfaces are delivered by Apple in special packages known as Frameworks. A framework is a directory that comprises of a shared library and several resources like images, header files, helper apps, etc, to sustain that library.</p>
                                                <h5 className='mt-3 mb-3'>Developer Library</h5> 
                                                <p>Developer Library is a very essential resource during development which comprises of API reference, release notes, sample code, tech notes, and programming guides.</p>
                                           
                                              </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
  )
}

export default React.memo(Ios);
