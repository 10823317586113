import React from 'react'
import PageHelmet from "../component/common/Helmet";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Zoom } from 'react-reveal';
import Fade from 'react-reveal/Fade';
import { useState } from 'react';
// import Breadcrumb from '../elements/common/Breadcrumb';


const AllPortFolio = [
    {
        url: "https://www.danovel.com/",
        imageUrl: "1",
        title: "DANOVEL PTE LTD",

        alt: "Wordpress Development PortFolio ",
        label: "Wordpress"
    },
    {
        url: "https://noble-manhattan.com/",
        imageUrl: "2",
        title: "Noble Manhattan Coaching Ltd",

        alt: "Wordpress Theme Development ",
        label: "Wordpress"
    },
    {
        url: "https://www.toltecnumerology.com/",
        imageUrl: "3",
        title: "Toltec Numerology",

        alt: "Wordpress Customization Development  PortFolio",
        label: "Wordpress"
    },
    {
        url: "https://1800unlocks.com/",
        imageUrl: "4",
        title: "1-800-Unlocks",

        alt: "Wordpress Customization Development  PortFolio",
        label: "Wordpress"
    },
    {
        url: "https://1800unlocks.com/",
        imageUrl: "5",
        title: "THE GLOBAL CIRCLE CLUB",

        alt: "Wordpress Customization Development  PortFolio",
        label: "Wordpress"
    },
    {
        url: "https://www.lifeafterbugs.com/",
        imageUrl: "6",
        title: "Lifeafterbugs",

        alt: "Wordpress Customization Development  PortFolio",
        label: "Wordpress"
    },
    {
        url: "https://www.cosavfx.com/",
        imageUrl: "7",
        title: "Cosavfx",

        alt: "Wordpress Customization Development  PortFolio",
        label: "Wordpress"
    },
]
const AllWeb = [

    {
        url: "https://www.danovel.com/",
        imageUrl: "1",
        title: "DANOVEL PTE LTD",

        alt: "Web Development PortFolio ",
        label: "React"
    },
    {
        url: "https://noble-manhattan.com/",
        imageUrl: "2",
        title: "Noble Manhattan Coaching Ltd",

        alt: "Web Development PortFolio ",
        label: "Angular"
    },
    {
        url: "https://www.toltecnumerology.com/",
        imageUrl: "3",
        title: "Toltec Numerology",

        alt: "Web Development PortFolio",
        label: "Vue"
    },
]
const AllMobile = [

    {
        url: "https://1800unlocks.com/",
        imageUrl: "4",
        title: "1-800-Unlocks",

        alt: "Web Development PortFolio",
        label: "Flutter "
    },
    {
        url: "https://1800unlocks.com/",
        imageUrl: "5",
        title: "THE GLOBAL CIRCLE CLUB",

        alt: "Web Development PortFolio",
        label: "React Native"
    },
    {
        url: "https://www.lifeafterbugs.com/",
        imageUrl: "6",
        title: "Lifeafterbugs",

        alt: "Web Development PortFolio",
        label: "Android"
    },
    {
        url: "https://www.cosavfx.com/",
        imageUrl: "7",
        title: "Cosavfx",

        alt: "Web Development PortFolio",
        label: ""
    },

]
const AllWordpress = [

    {
        url: "https://1800unlocks.com/",
        imageUrl: "4",
        title: "1-800-Unlocks",

        alt: "Web Development PortFolio",
        label: "Wordpress"
    },
    {
        url: "https://1800unlocks.com/",
        imageUrl: "5",
        title: "THE GLOBAL CIRCLE CLUB",

        alt: "Web Development PortFolio",
        label: "Wordpress"
    },


]

const AllLanding = [

    {
        url: "https://www.danovel.com/",
        imageUrl: "1",
        title: "DANOVEL PTE LTD",

        alt: "Web Development PortFolio ",
        label: "Landing"
    },
    {
        url: "https://noble-manhattan.com/",
        imageUrl: "2",
        title: "Noble Manhattan Coaching Ltd",

        alt: "Web Development PortFolio ",
        label: "Landing"
    },

]
const AllNew = [
    {
        url: "https://www.toltecnumerology.com/",
        imageUrl: "3",
        title: "Toltec Numerology",

        alt: "Web Development PortFolio",
        label: "e-commerce"
    },
    {
        url: "https://1800unlocks.com/",
        imageUrl: "4",
        title: "1-800-Unlocks",

        alt: "Web Development PortFolio",
        label: "e-commerce"
    },
    {
        url: "https://1800unlocks.com/",
        imageUrl: "5",
        title: "THE GLOBAL CIRCLE CLUB",

        alt: "Web Development PortFolio",
        label: "e-commerce"
    },

]

const ProductShowCase = () => {

    const [isShown, setIsShown] = useState(true);
    const [isShown1, setIsShown1] = useState(false);
    const [isShown2, setIsShown2] = useState(false);
    const [isShown3, setIsShown3] = useState(false);
    const [isShown4, setIsShown4] = useState(false);
    const [isShown5, setIsShown5] = useState(false);
    const [isShown6, setIsShown6] = useState(false);

    return (
        <React.Fragment>

            <PageHelmet
                pageTitle="Best IT Services Company in Lucknow | PortFolio"
                metaKeywords="Best IT Services Company in Lucknow "
                metaDescription=" If you are searching for the IT Services Company in Lucknow Ekana Technologies is a best IT Services Company in Lucknow at low price "
                canonical="/Best-IT-Services-Company-in-Lucknow-PortFolio"

            />

            <div className="rn-page-title-area  ptb--30  bg_color--1"  >
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner popup-image">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content align-items-center" >
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 order-2 order-lg-1">
                                            <h1 className='title text-center'>Our Portfolio Show Case - Ekana Technologies</h1>
                                        </div>
                                        <div className="col-lg-7 col-md-7 col-sm-12 col-12 order-2 order-lg-1 ">
                                            <Fade bottom>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="rn-portfolio-post "
                                                            onMouseEnter={() => setIsShown(true)}
                                                            onMouseLeave={() => setIsShown(false)}
                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/information-technology.png" loading="lazy" alt="portfolio icons" />

                                                            <Zoom >
                                                                <div className="inner">



                                                                    <p >Ekana technologies has a wider sky of technologies and work domains which is stabilized with trust and years of experience.</p>
                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>
                                            <Fade bottom delay={1000}>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div className="rn-portfolio-post  "

                                                            onMouseEnter={() => setIsShown1(true)}
                                                            onMouseLeave={() => setIsShown1(false)}
                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/Information-Technologys.png" loading="lazy" alt="portfolio icons" />
                                                            <Zoom >
                                                                <div className="inner">
                                                                    <h2 className="title">Variety of technologies</h2>
                                                                    <p >
                                                                        We have worked in most of technologies because we believe in updating ourselves with latest technolgies.
                                                                    </p>
                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>
                                            <Fade bottom delay={1500}>
                                                <div className="row">
                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                                        <div className="rn-portfolio-post"
                                                            onMouseEnter={() => setIsShown2(true)}
                                                            onMouseLeave={() => setIsShown2(false)}

                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/time-management.png" loading="lazy" alt="portfolio icons" />
                                                            <Zoom >
                                                                <div className="inner">
                                                                    <h2 className="title">Time</h2>
                                                                    <p >
                                                                        In ekana technolgies we know the value of time . Most of our projects are delivered before the timeline
                                                                    </p>
                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>
                                            <Fade bottom delay={2000}>
                                                <div className="row" >
                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                                        <div className="rn-portfolio-post"

                                                            onMouseEnter={() => setIsShown3(true)}
                                                            onMouseLeave={() => setIsShown3(false)}

                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/guidances.jpeg" loading="lazy" alt="portfolio icons" />
                                                            <Zoom >
                                                                <div className="inner">
                                                                    <h2 className="title">Guidance</h2>
                                                                    <p >
                                                                        We provide proper guidance to our clients from a to z. may it be choice of right technology, development, promotion or seo which makes us the first choice among startups.
                                                                    </p>
                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>
                                            <Fade bottom delay={2000}>
                                                <div className="row" >
                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                                        <div className="rn-portfolio-post"

                                                            onMouseEnter={() => setIsShown4(true)}
                                                            onMouseLeave={() => setIsShown4(false)}

                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/support.png" loading="lazy" alt="portfolio icons" />
                                                            <Zoom >
                                                                <div className="inner">
                                                                    <h2 className="title">Support</h2>
                                                                    <p >
                                                                        Our support team is always ready to help you out. May it be your first step in technology or you have a working structure we are always happy to help you.
                                                                    </p>
                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>

                                            <Fade bottom delay={2000}>
                                                <div className="row" >
                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                                        <div className="rn-portfolio-post"

                                                            onMouseEnter={() => setIsShown5(true)}
                                                            onMouseLeave={() => setIsShown5(false)}

                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/budget.png" loading="lazy" alt="portfolio icons" />
                                                            <Zoom >
                                                                <div className="inner">
                                                                    <h2 className="title">Budget</h2>
                                                                    <p>There are many who dream big and we never let money tackle their way in IT Industry. </p>


                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>

                                            <Fade bottom delay={2000}>
                                                <div className="row" >
                                                    <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                                                        <div className="rn-portfolio-post"

                                                            onMouseEnter={() => setIsShown6(true)}
                                                            onMouseLeave={() => setIsShown6(false)}

                                                        >
                                                            <img className="icon-5" src="assets/images/portfolio/16years-experience.png" loading="lazy" alt="portfolio icons" />
                                                            <Zoom >
                                                                <div className="inner">
                                                                    <h2 className="title">Years of experience</h2>
                                                                    <p>In our more than 16 glorious years of experience we have earned trust from our clients.</p>

                                                                </div>
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fade>

                                        </div>

                                        <div className="col-lg-5 col-md-7 col-sm-12 col-12 order-1 order-lg-2  ">


                                            {isShown && (
                                                <Fade right>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/iot_1.jpg" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}

                                            {isShown1 && (
                                                <Fade bottom>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/sco-in.png" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}



                                            {isShown2 && (
                                                <Fade right>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/andrio.jpg" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}

                                            {isShown3 && (
                                                <Fade bottom>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/artificial-intelligence.jpg" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}

                                            {isShown4 && (
                                                <Fade right>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/php_sql_code.jpg" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}

                                            {isShown5 && (
                                                <Fade bottom>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/ux-pro.jpg" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}

                                            {isShown6 && (
                                                <Fade right>
                                                    <div className='text-center'>

                                                        <img className="w-100 " src="assets/images/training/sql-.jpg" loading="lazy" alt="Service Images" />

                                                    </div>
                                                </Fade>

                                            )}

                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <div className="container">

                {/* Start PortFolio */}
                <div className="home-demo-area bg_color--1 ">
                    <div className="wrapper">

                        <Tabs>
                            <div className="row text-center">
                                <div className="col-lg-12">
                                    <div className="tablist-inner">
                                        <TabList className="pv-tab-button text-center">
                                            <Tab><span>All PortFolio</span></Tab>
                                            <Tab><span>Web </span></Tab>
                                            <Tab><span>Mobile</span></Tab>
                                            <Tab><span>Wordpress</span></Tab>
                                            <Tab><span>Landing</span></Tab>
                                            <Tab><span>New</span></Tab>
                                        </TabList>
                                    </div>
                                </div>
                            </div>

                            <TabPanel className="row bg_color--1">

                                {AllPortFolio.map((val, index) => (

                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            {
                                                val.url ? <a target="_blank" rel="noopener noreferrer" href={`${val.url} `}>

                                                    <div className="screen">
                                                        {
                                                            val.imageUrl ? <img src={`assets/images/portfolio/big/${val.imageUrl}.png`} loading="lazy" alt={`${val.alt}`} /> : <h3 className="title">{val.alt}</h3>
                                                        }

                                                    </div>
                                                    {val.title ? <h3 className="title text-center">{val.title}</h3> : ''}
                                                    {val.des ? <p className="info">{val.des}</p> : ''}

                                                    {val.label ? <div className="label-new"><span>{val.label}</span></div> : ''}
                                                </a>
                                                    : ''
                                            }


                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}




                            </TabPanel>


                            <TabPanel className="row bg_color--1">

                                {AllWeb.map((val, index) => (

                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            {
                                                val.url ? <a target="_blank" rel="noopener noreferrer" href={`/${val.url} `}>

                                                    <div className="screen">
                                                        {
                                                            val.imageUrl ? <img src={`assets/images/portfolio/big/${val.imageUrl}.png`} loading="lazy" alt={`${val.alt}`} /> : <h3 className="title">{val.alt}</h3>
                                                        }

                                                    </div>
                                                    {val.title ? <h3 className="title text-center">{val.title}</h3> : ''}
                                                    {val.des ? <p className="info">{val.des}</p> : ''}

                                                    {val.label ? <div className="label-new"><span>{val.label}</span></div> : ''}
                                                </a>
                                                    : ''
                                            }


                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}




                            </TabPanel>

                            <TabPanel className="row bg_color--1">

                                {AllMobile.map((val, index) => (

                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            {
                                                val.url ? <a target="_blank" rel="noopener noreferrer" href={`/${val.url} `}>

                                                    <div className="screen">
                                                        {
                                                            val.imageUrl ? <img src={`assets/images/portfolio/big/${val.imageUrl}.png`} loading="lazy" alt={`${val.alt}`} /> : <h3 className="title">{val.alt}</h3>
                                                        }

                                                    </div>
                                                    {val.title ? <h3 className="title text-center">{val.title}</h3> : ''}
                                                    {val.des ? <p className="info">{val.des}</p> : ''}

                                                    {val.label ? <div className="label-new"><span>{val.label}</span></div> : ''}
                                                </a>
                                                    : ''
                                            }


                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}




                            </TabPanel>

                            <TabPanel className="row bg_color--1">

                                {AllWordpress.map((val, index) => (

                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            {
                                                val.url ? <a target="_blank" rel="noopener noreferrer" href={`/${val.url} `}>

                                                    <div className="screen">
                                                        {
                                                            val.imageUrl ? <img src={`assets/images/portfolio/big/${val.imageUrl}.png`} loading="lazy" alt={`${val.alt}`} /> : <h3 className="title">{val.alt}</h3>
                                                        }

                                                    </div>
                                                    {val.title ? <h3 className="title text-center">{val.title}</h3> : ''}
                                                    {val.des ? <p className="info">{val.des}</p> : ''}

                                                    {val.label ? <div className="label-new"><span>{val.label}</span></div> : ''}
                                                </a>
                                                    : ''
                                            }


                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}




                            </TabPanel>

                            <TabPanel className="row bg_color--1">

                                {AllLanding.map((val, index) => (

                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            {
                                                val.url ? <a target="_blank" rel="noopener noreferrer" href={`/${val.url} `}>

                                                    <div className="screen">
                                                        {
                                                            val.imageUrl ? <img src={`assets/images/portfolio/big/${val.imageUrl}.png`} loading="lazy" alt={`${val.alt}`} /> : <h3 className="title">{val.alt}</h3>
                                                        }

                                                    </div>
                                                    {val.title ? <h3 className="title text-center">{val.title}</h3> : ''}
                                                    {val.des ? <p className="info">{val.des}</p> : ''}

                                                    {val.label ? <div className="label-new"><span>{val.label}</span></div> : ''}
                                                </a>
                                                    : ''
                                            }


                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}




                            </TabPanel>

                            <TabPanel className="row bg_color--1">

                                {AllNew.map((val, index) => (

                                    <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-12">
                                        <div className="single-demo">
                                            {
                                                val.url ? <a target="_blank" rel="noopener noreferrer" href={`/${val.url} `}>

                                                    <div className="screen">
                                                        {
                                                            val.imageUrl ? <img src={`assets/images/portfolio/big/${val.imageUrl}.png`} loading="lazy" alt={`${val.alt}`} /> : <h3 className="title">{val.alt}</h3>
                                                        }

                                                    </div>
                                                    {val.title ? <h3 className="title text-center">{val.title}</h3> : ''}
                                                    {val.des ? <p className="info">{val.des}</p> : ''}

                                                    {val.label ? <div className="label-new"><span>{val.label}</span></div> : ''}
                                                </a>
                                                    : ''
                                            }


                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}




                            </TabPanel>

                        </Tabs>

                    </div>
                </div>
                {/* End PortFolio  */}


            </div>

        </React.Fragment>
    )
}

export default ProductShowCase;
