import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const Wordpresstheme = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            pageTitle='Best WordPress Theme Development Company in Lucknow' 

            metaKeywords="Best WordPress Theme Development Company in Lucknow, wordpress development company in lucknow, best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
     
            metaDescription=" If you are searching for Best WordPress Theme Development Company in Lucknow Ekana Technologies is a Top Digital Marketing and Software Development Company in Lucknow."
          
            canonical="/Best-WordPress-Theme-Development-Company-in-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={3} parent={"Services"} title={' WordPress Theme Development'} />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>We endeavor to offer you WordPress theme development services that let you transform your themes to solutions that are all-inclusive and versatile.  While tailoring your themes to suit your business needs, we take every measure to keep it responsive and compatible across different platforms. Without falling prey to the non-effective ways of theme development, we deliver services that make this customization a pleasant experience.</p>
                                                    <p>The maze of complexity associated with the theme development is carefully considered and broken into simpler, smaller tasks so that your website is poised for accelerated growth. It is further coupled with state-of-the-art processes to enhance your WordPress and fuel it to deliver greater value.To make sure that the final development fulfills your expectations, we deliver WordPress theme development services that are agile and help you in pitching the most qualitatively optimized and dynamic theme to the end-user.</p>
                                                    <p>We give full attention to the needs of the business so that the solution strategy can be defined along with the development of a detailed roadmap for implementation. Based on the detailed roadmap and developed architecture during the assessment and specification stage, we deliver custom-built solutions with comprehensive business logic, efficient performance and technical scalability.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}

            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                < DiscussProject />
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(Wordpresstheme);
