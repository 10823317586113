import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const ComponentDevelopment = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            pageTitle='Best Software Component Development Company in Lucknow' 
            metaKeywords=" best digital marketing company Lucknow, seo company in lucknow, best software company in lucknow,app development company in lucknow, wordpress development company in lucknow, best It company in lucknow,Top 5 IT company in lucknow, Website Development, App Development , Accounting Softwares, MLM Softwares, Hospital management Softwares, ERP Software,best digital marketing agency in Lucknow, website creators "
            metaDescription=" Software Development Company in Lucknow Ekana Technologies is the fastest-growing Digital Marketing Company in Lucknow. We are the Best App development company"
            canonical="/Best-Software-Component-Development-Company-in-Lucknow"
            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={1} parent={"Services"}  title={'Component Development'} />
            {/* End Breadcrump Area */}







            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>
                                                    We design custom websites that cater to the need of the client. Our developers love to work for Brands looking to deliver a one-of-kind user experience. We use a variety of website design software to create custom website designs that are responsive and user-friendly. Ekana Technologies follow the Web development approach to build web solutions. We build all systems on the foundation laid by us so that you get a rich set of tools and services even before the work on your site has even begun. It also means that your dynamic website can be up and running within a short span of time, with powerful CMS features, plug-ins, and database applications already running. This reduces our development efforts as many websites do not require any further development. We have developed many working plug-in modules that may be close to what you require. In such a case, your customizations may only involve changes to these existing systems that are already debugged and tested in real-world use.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}




            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>




            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(ComponentDevelopment);
