import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";
import { Parallax } from 'react-parallax';
// import BrandTwo from "../../../elements/BrandTwo";
import DiscussProject from '../../../component/common/DiscussProject';

function LogoDesign() {
    return (
        <React.Fragment>
            <PageHelmet  
            
            pageTitle="Best Logo Design Company in Lucknow"
            metaKeywords="Best Logo Design Company in Lucknow "
            metaDescription=" If you are searching for the Logo Design Company in Lucknow Ekana Technologies is a best Logo Design Company in Lucknow at low price "
   
            canonical="/Best-Logo-Design-Company-in-Lucknow"
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={12} parent={"Services"} title={'Logo Design'} />
            {/* End Breadcrump Area */}



            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">


                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                        <div className="row sercice-details-content align-items-center">

                                            <div className="col-lg-12 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Our designing team analyzes your brand's potential users, their needs, market trends and then strategically designs a logo to empower your business with a distinct brand entity. We give your business a corporate, original, creative and specifically tailored look that expresses your values, personality and competitive edge.</p>
                                                    <p>It's a matter of pride for us to have a highly skilled and competent team of graphic designers who can provide the best graphic design solutions. Our team makes you aware of how your competitors are leveraging the power of graphic design. While designing a logo we do the research regarding how your business can stand out by changing your strategies.</p>
                                                    <h5 className='mt-3 mb-3'>Why Choose Us?</h5>
                                                    <ul className="liststyle">
                                                        <li>Understands the client’s business requirements and objectives.</li>
                                                        <li>Competitor analysis and legal brand protection.</li>
                                                        <li>Render a customized corporate logo.</li>
                                                        <li>Compelling, communicative professional logo design.</li>
                                                        <li>Cost-effective and reliable solution.</li>
                                                        <li>100% customer satisfaction.</li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </Zoom>
                                    {/* End Single Area */}





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}



            {/* Start Brand Area */}
            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
                <div className="brand-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                < DiscussProject />
                            </div>
                        </div>
                    </div>
                </div>

            </Parallax>
            {/* End Brand Area */}





            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(LogoDesign);
