import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";
import { Parallax } from 'react-parallax';
// import BrandTwo from "../../../elements/BrandTwo";
import DiscussProject from '../../../component/common/DiscussProject';


const SearchEngine = () => {
  return (
    <React.Fragment>
      <PageHelmet 
         pageTitle='best Search Engine Optimization company in lucknow' 
            
         metaKeywords="best Search Engine Optimization company in lucknow,best digital marketing company Lucknow, seo company in lucknow, best digital marketing agency in Lucknow, website creators "

         metaDescription=" If you are searching for the Best SEO Company in Lucknow Ekana Technologies is a Best Digital Marketing and Software Development Company in Lucknow at low price "
       
         canonical="/best-Search-Engine-Optimization-company-in-lucknow"
      />

      {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
      {/* Start Breadcrump Area */}
      <Breadcrumb No={8} parent={"Services"} title={'Search Engine Optimization'} />
      {/* End Breadcrump Area */}







      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">


                  {/* Start Single Area */}
                  <Zoom bottom>
                    <div className="row sercice-details-content align-items-center">

                      <div className="col-lg-12 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>To maintain your company relevancy we assist you with the best pay per click management strategy along with the PPC marketing plan. This will help in boosting your brand awareness and increasing the revenue flow.</p>
                          <p>The hierarchy which we follow in the development of a customized promotion for you is the investigation of your paid performances, then we evaluate the drawback of the execution and finally, we consider the plan for future improvement.</p>
                          <h5 className='mt-3 mb-3'>While setting out your Pay Per Click account and paid media campaign, we always ensure that the even the minutest detail is taken into account which comprises:-</h5>
                          <ul className="liststyle">
                            <li>PPC Research and Planning</li>
                            <li>PPC Keyword Analysis</li>
                            <li>PPC Strategy</li>
                            <li>PPC Deployment and Testing</li>
                            <li>PPC Management</li>

                          </ul>

                          <p className='mt-3'>Our excellent group of professionals who have excelled in PPC specialization will assist you. Our involvement in managing the PPC campaign will take off all your anxiety. Working together we promise to meet the target by ensuring that you get value worth of every penny you spent on your paid media strategy.</p>
                        </div>
                      </div>

                    </div>
                  </Zoom>
                  {/* End Single Area */}




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}



      {/* Start Brand Area */}
      <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject />
              </div>
            </div>
          </div>
        </div>

      </Parallax>
      {/* End Brand Area */}





      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* <Footer /> */}

    </React.Fragment>
  )
}

export default React.memo(SearchEngine);

