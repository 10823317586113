import React from 'react'
import Zoom from 'react-reveal/Zoom';
import PageHelmet from "../../../component/common/Helmet";
import Breadcrumb from "../../../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Parallax } from 'react-parallax';
import DiscussProject from '../../../component/common/DiscussProject';
// import Header from "../../../component/header/Header";
// import Footer from "../../../component/footer/Footer";


const HybridApp = () => {
    return (
        <React.Fragment>
            <PageHelmet 
            
            pageTitle="Best Hybrid App Development Company in Lucknow"
            metaKeywords="Best Hybrid App DevelopmentCompany in Lucknow "
            metaDescription=" If you are searching for the Hybrid App Development Company in Lucknow Ekana Technologies is a best Mobile App Development Company in Lucknow at low price "

            canonical="/Best-Hybrid-App-Development-Company-in-Lucknow"

            
            />

            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* Start Breadcrump Area */}
            <Breadcrumb No={4} parent={"Services"}  title={'Hybrid App Development'} />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper */}
            <div className="rn-service-details pb--60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <Zoom bottom>
                                    <div className="row sercice-details-content align-items-center">

                                        <div className="col-lg-12 col-12 order-2 order-lg-1">
                                            <div className="details mt_md--30 mt_sm--30">

                                                <p>The availability of various mobile platforms each with its own SDKs, user interfaces and programming language has given rise to the fragmentation issue. Thus enterprises think across multiple platforms and devices before going for any of the mobile application development services. In today’s marketplace where the future is uncertain, one should go for the hybrid mobile app as it proffers a middle ground to attain success for your mobile strategy.</p>
                                                <p>Hybrid mobile apps that are developed by Ekana technologies facilitate better market penetration in a short span of time. Fully responsive hybrid apps, makes it possible to switch from one platform to the other without making any changes in the data to a new format.</p>
                                                <p>Our Hybrid Mobile Apps Development Services</p>

                                                <ul className="liststyle">
                                                    <li>Adaptability, easy deployment, and maintenance.</li>
                                                    <li>High-level performance.</li>
                                                    <li>Compatible across multiple platforms.</li>
                                                    <li>Allocation allowed through both the App Store and Google Play.</li>

                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    </Zoom>
                                    {/* End Single Area */}




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}


            <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={`/assets/images/bg/bg-image-22.webp`} strength={1000}>
        <div className="brand-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                < DiscussProject/>
              </div>
            </div>
          </div>
        </div>

      </Parallax>

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* <Footer /> */}

        </React.Fragment>
    )
}

export default React.memo(HybridApp);
